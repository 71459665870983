<a
  mat-button
  data-test-id="open-url-in-new-window"
  [href]="url"
  [attr.aria-label]="text"
  [matTooltip]="text"
  [download]="targetName"
  [color]="'primary'"
>
  <mat-icon>save_alt</mat-icon>
  <span class="text-sm">{{ text }}</span>
</a>
