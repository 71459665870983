/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { BinaryFileSharedModule } from '@alfa-client/binary-file-shared';
import { CommandSharedModule } from '@alfa-client/command-shared';
import { NavigationSharedModule } from '@alfa-client/navigation-shared';
import { TechSharedModule } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RestModule } from '@ngxp/rest';
import { VorgangEffects } from './+state/vorgang.effects';
import { VorgangFacade } from './+state/vorgang.facade';
import { VorgangHttpErrorService } from './vorgang-http-error.service';

import * as fromVorgang from './+state/vorgang.reducer';

@NgModule({
  imports: [
    CommonModule,
    RestModule,
    TechSharedModule,
    CommandSharedModule,
    BinaryFileSharedModule,
    RouterModule,
    StoreModule.forFeature(fromVorgang.VORGANG_FEATURE_KEY, fromVorgang.reducer),
    EffectsModule.forFeature([VorgangEffects]),
    NavigationSharedModule,
  ],
  providers: [VorgangFacade],
})
export class VorgangSharedModule {
  constructor(private vorgangErrorSevice: VorgangHttpErrorService) {}
}
