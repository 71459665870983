import { convertForDataTest, EMPTY_STRING, TechSharedModule } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { cva, VariantProps } from 'class-variance-authority';
import { ErrorMessageComponent } from '../error-message/error-message.component';

const textInputVariants = cva(
  [
    'w-full box-border rounded-lg border bg-background-50 px-3 py-2 text-base text-text leading-5',
    'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
  ],
  {
    variants: {
      variant: {
        default:
          'border-primary-600/50 focus-visible:outline-focus focus-visible:border-background-200 hover:border-primary-hover',
        error:
          'border-error/50 hover:border-error focus-visible:outline-error focus-visible:border-background-200',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);
type TextInputVariants = VariantProps<typeof textInputVariants>;

@Component({
  selector: 'ods-text-input',
  standalone: true,
  imports: [CommonModule, ErrorMessageComponent, ReactiveFormsModule, TechSharedModule],
  template: `
    <div class="relative">
      <label *ngIf="showLabel" [for]="id" class="text-md mb-2 block font-medium text-text">
        {{ inputLabel }}<ng-container *ngIf="required"><i aria-hidden="true">*</i></ng-container>
      </label>
      <div class="mt-2">
        <div
          *ngIf="withPrefix"
          class="pointer-events-none absolute bottom-2 left-2 flex size-6 items-center justify-center"
        >
          <ng-content select="[prefix]" />
        </div>
        <input
          type="text"
          [id]="id"
          [formControl]="fieldControl"
          [ngClass]="[
            textInputVariants({ variant }),
            withPrefix ? 'pl-10' : '',
            withSuffix ? 'pr-10' : '',
          ]"
          [placeholder]="placeholder"
          [autocomplete]="autocomplete"
          [attr.aria-required]="required"
          [attr.aria-invalid]="variant === 'error'"
          [attr.data-test-id]="(inputLabel | convertForDataTest) + '-text-input'"
          (click)="clickEmitter.emit()"
          #inputElement
        />
        <div
          *ngIf="withSuffix"
          class="absolute bottom-2 right-2 flex size-6 items-center justify-center"
        >
          <ng-content select="[suffix]" />
        </div>
      </div>
      <ng-content select="[error]"></ng-content>
    </div>
  `,
})
export class TextInputComponent {
  @ViewChild('inputElement') inputElement: ElementRef;

  @Input({ required: true }) set label(label: string) {
    this.inputLabel = label;
    this.id = convertForDataTest(label);
  }
  @Input() placeholder: string = '';
  @Input() autocomplete: string = 'off';
  @Input() variant: TextInputVariants['variant'];
  @Input() fieldControl: FormControl = new FormControl(EMPTY_STRING);
  @Input() required: boolean = false;
  @Input() withPrefix: boolean = false;
  @Input() withSuffix: boolean = false;
  @Input() showLabel: boolean = true;

  @Input() set focus(value: boolean) {
    if (value && this.inputElement) {
      this.inputElement.nativeElement.focus();
    }
  }

  @Output() clickEmitter: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  inputLabel: string;
  id: string;
  textInputVariants = textInputVariants;
}
