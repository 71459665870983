import { Component, Input } from '@angular/core';

@Component({
  selector: 'alfa-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
})
export class DocumentationComponent {
  @Input() url: string;
  @Input() menuTitle: string;
}
