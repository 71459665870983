import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HintFacade } from './+state/hint.facade';

@Injectable()
export class HintService {
  constructor(private facade: HintFacade) {}

  public hasUserNoOrganisationsEinheitIdHint(): Observable<boolean> {
    return this.facade.hasUserNoOrganisationsEinheitIdHint();
  }
}
