import { Component, Input } from '@angular/core';
import { StateResource, createEmptyStateResource } from '@alfa-client/tech-shared';
import { Resource } from '@ngxp/rest';

@Component({
  selector: 'ozgcloud-spinner-transparency',
  templateUrl: './spinner-transparency.component.html',
  styleUrls: ['./spinner-transparency.component.scss'],
})
export class SpinnerTransparencyComponent {
  @Input() stateResource: StateResource<Resource> = createEmptyStateResource<Resource>();

  get showSpinner(): boolean {
    return this.stateResource.loading || this.stateResource.reload;
  }
}
