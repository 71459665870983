import { TechSharedModule } from '@alfa-client/tech-shared';
import { UiModule } from '@alfa-client/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DocumentationComponent } from './help-menu/documentation/documentation.component';
import { OpenDocumentationButtonComponent } from './help-menu/documentation/open-documentation-button/open-documentation-button.component';
import { HelpMenuComponent } from './help-menu/help-menu.component';

@NgModule({
  imports: [CommonModule, TechSharedModule, UiModule],
  declarations: [HelpMenuComponent, DocumentationComponent, OpenDocumentationButtonComponent],
  exports: [HelpMenuComponent],
})
export class UserAssistanceModule {}
