import { Component, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'ozgcloud-menu',
  templateUrl: './ozgcloud-menu.component.html',
  styleUrls: ['./ozgcloud-menu.component.scss'],
})
export class OzgcloudMenuComponent {
  @ViewChild('menu', { static: true }) matMenu: MatMenu;
}
