/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { BinaryFileListResource } from '@alfa-client/binary-file-shared';
import { CommandOrder, CommandResource } from '@alfa-client/command-shared';
import { EMPTY_STRING, StateResource } from '@alfa-client/tech-shared';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { buildBackButtonUrl, buildVorgangFilterViewRoutePath } from '../vorgang-navigation.util';
import {
  StatusCommandMap,
  VorgangFilter,
  VorgangListResource,
  VorgangResource,
  VorgangStatistic,
  VorgangView,
  VorgangWithEingangResource,
} from '../vorgang.model';
import { VORGANG_FEATURE_KEY, VorgangState } from './vorgang.reducer';

export const getVorgangState: MemoizedSelector<object, VorgangState> =
  createFeatureSelector<VorgangState>(VORGANG_FEATURE_KEY);

// VorgangList
export const vorgangList: MemoizedSelector<
  VorgangState,
  StateResource<VorgangListResource>
> = createSelector(getVorgangState, (state: VorgangState) => state.vorgangList);
export const vorgaenge: MemoizedSelector<VorgangState, VorgangResource[]> = createSelector(
  getVorgangState,
  (state: VorgangState) => state.vorgaenge,
);
export const vorgangStatistic: MemoizedSelector<
  VorgangState,
  StateResource<VorgangStatistic>
> = createSelector(getVorgangState, (state: VorgangState) => state.vorgangStatistic);

export const vorgangFilter = createSelector(
  getVorgangState,
  (state: VorgangState) => state.vorgangFilter,
);
export const vorgangView = createSelector(
  getVorgangState,
  (state: VorgangState) => state.vorgangView,
);

export const searchPreviewList: MemoizedSelector<
  VorgangState,
  StateResource<VorgangListResource>
> = createSelector(getVorgangState, (state: VorgangState) => state.searchPreviewList);
export const searchString: MemoizedSelector<VorgangState, string> = createSelector(
  getVorgangState,
  (state: VorgangState) => state.searchString,
);

export const backButtonUrl: MemoizedSelector<VorgangState, string> = createSelector(
  getVorgangState,
  (state: VorgangState) => buildBackButtonUrl(state),
);

export const isVorgangViewSelected = (view: VorgangView) =>
  createSelector(vorgangView, (vorgangViewInState: VorgangView) => vorgangViewInState === view);
export const getVorgangViewRoutePath = (view: VorgangView) =>
  createSelector(vorgangFilter, (vorgangFilterInState: VorgangFilter) =>
    buildVorgangFilterViewRoutePath(vorgangFilterInState, view, EMPTY_STRING),
  );

export const isVorgangFilterSelected = (filter: VorgangFilter) =>
  createSelector(
    vorgangFilter,
    (vorgangFilterInState: VorgangFilter) => vorgangFilterInState === filter,
  );
export const getVorgangFilterRoutePath = (filter: VorgangFilter) =>
  createSelector(
    vorgangView,
    searchString,
    (vorgangViewInState: VorgangView, searchStringInState: string) =>
      buildVorgangFilterViewRoutePath(filter, vorgangViewInState, searchStringInState),
  );

//VorgangWithEingang
export const vorgangWithEingang: MemoizedSelector<
  VorgangState,
  StateResource<VorgangWithEingangResource>
> = createSelector(getVorgangState, (state: VorgangState) => state.vorgangWithEingang);
export const attachmentList: MemoizedSelector<
  VorgangState,
  StateResource<BinaryFileListResource>
> = createSelector(getVorgangState, (state: VorgangState) => state.attachmentList);
export const representationList: MemoizedSelector<
  VorgangState,
  StateResource<BinaryFileListResource>
> = createSelector(getVorgangState, (state: VorgangState) => state.representationList);
export const forwardPendingCommand: MemoizedSelector<
  VorgangState,
  StateResource<CommandResource>
> = createSelector(getVorgangState, (state: VorgangState) => state.forwardPendingCommand);
export const sendPostfachNachrichtPendingCommand: MemoizedSelector<
  VorgangState,
  StateResource<CommandResource>
> = createSelector(
  getVorgangState,
  (state: VorgangState) => state.sendPostfachNachrichtPendingCommand,
);

export const assignUserCommand: MemoizedSelector<
  VorgangState,
  StateResource<CommandResource>
> = createSelector(getVorgangState, (state: VorgangState) => state.assignUserCommand);

export const statusCommandMap: MemoizedSelector<VorgangState, StatusCommandMap> = createSelector(
  getVorgangState,
  (state: VorgangState) => state.statusCommandMap,
);
export const getStatusCommand = (order: CommandOrder) =>
  createSelector(
    statusCommandMap,
    (statusCommandMapInState: StatusCommandMap) => statusCommandMapInState[order],
  );

export const revokeCommand: MemoizedSelector<
  VorgangState,
  StateResource<CommandResource>
> = createSelector(getVorgangState, (state: VorgangState) => state.revokeCommand);

export const vorgangExport: MemoizedSelector<VorgangState, StateResource<boolean>> = createSelector(
  getVorgangState,
  (state: VorgangState) => state.vorgangExport,
);
