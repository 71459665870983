import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as HintSelectors from './hint.selectors';

@Injectable()
export class HintFacade {
  constructor(private store: Store) {}

  public hasUserNoOrganisationsEinheitIdHint(): Observable<boolean> {
    return this.store.select(HintSelectors.hasUserNoOrganisationsEinheitIdHint);
  }
}
