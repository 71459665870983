import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { USER_PROFILE_FEATURE_KEY, UserProfileState } from './user-profile.reducer';

export const getUserProfileState: MemoizedSelector<object, UserProfileState> =
  createFeatureSelector<UserProfileState>(USER_PROFILE_FEATURE_KEY);

export const userProfileSearchVisibilty: MemoizedSelector<UserProfileState, boolean> =
  createSelector(
    getUserProfileState,
    (state: UserProfileState) => state.userProfileSearchVisibilty,
  );
