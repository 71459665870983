import { ApiRootLinkRel, ApiRootResource, ApiRootService } from '@alfa-client/api-root-shared';
import { NavigationService } from '@alfa-client/navigation-shared';
import { ResourceRepository, StateResource, isLoaded, toResourceUri } from '@alfa-client/tech-shared';
import { Messages, SnackBarService } from '@alfa-client/ui';
import { Injectable } from '@angular/core';
import { Resource, ResourceUri, getUrl, hasLink } from '@ngxp/rest';
import { Observable, first, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ResourceLinkRel } from './resource-linkrel';

@Injectable({ providedIn: 'root' })
export class ResourceRedirectService {
  private readonly PARAM_URI_PLACEHOLDER: string = '{uri}';

  constructor(
    private apiRootService: ApiRootService,
    private repository: ResourceRepository,
    private snackbarService: SnackBarService,
    private navigationService: NavigationService,
  ) {}

  public redirectToResource(resourceUri: ResourceUri): void {
    this.getResolvedResource(resourceUri)
      .pipe(first())
      .subscribe({
        next: (resource: Resource) => this.navigateToResource(resource),
        error: () => this.handleResolveError(),
      });
  }

  getResolvedResource(resourceUri: string): Observable<Resource> {
    return this.apiRootService.getApiRoot().pipe(
      filter(isLoaded),
      switchMap((apiRoot: StateResource<ApiRootResource>) =>
        this.repository.getResource<Resource>(this.buildResolveUri(apiRoot.resource, resourceUri)),
      ),
    );
  }

  buildResolveUri(apiRootResource: ApiRootResource, resourceUri: ResourceUri): ResourceUri {
    return getUrl(apiRootResource, ApiRootLinkRel.RESOURCE).replace(this.PARAM_URI_PLACEHOLDER, encodeURIComponent(resourceUri));
  }

  navigateToResource(resource: Resource): void {
    if (hasLink(resource, ResourceLinkRel.VORGANG)) {
      const vorgangUri: ResourceUri = toResourceUri(resource, ResourceLinkRel.VORGANG);
      this.navigationService.navigateToVorgang(vorgangUri);
    }
  }

  handleResolveError(): void {
    this.snackbarService.showInfo(Messages.HTTP_STATUS_RESOURCE_NOT_FOUND);
    this.navigationService.navigateToVorgangList();
  }
}
