import { Component, Input } from '@angular/core';

@Component({
  selector: 'ozgcloud-routing-button',
  templateUrl: './ozgcloud-routing-button.component.html',
  styleUrls: ['./ozgcloud-routing-button.component.scss'],
})
export class OzgcloudRoutingButtonComponent {
  @Input() routerLinkPath: string;
}
