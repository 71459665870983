/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ResourceUri } from '@ngxp/rest';
import { FormControlEditorAbstractComponent } from 'libs/design-component/src/lib/form/formcontrol-editor.abstract.component';
import { isEmpty, isEqual, isNil } from 'lodash-es';

@Component({
  selector: 'ozgcloud-autocomplete-editor',
  templateUrl: './autocomplete-editor.component.html',
  styleUrls: ['./autocomplete-editor.component.scss'],
})
export class AutocompleteEditorComponent
  extends FormControlEditorAbstractComponent
  implements AfterViewInit
{
  @Input() label: string;
  @Input() values: Selectable[] = [];
  @Input() required: boolean = false;

  @Output() onKeyUp: EventEmitter<void> = new EventEmitter();
  @Output() onOptionSelected: EventEmitter<Selectable> = new EventEmitter();
  @Output() enterOnNoSelection: EventEmitter<void> = new EventEmitter();

  @ViewChild('autocompleteInput', { static: true }) public autocompleteInputElement: ElementRef;

  ngAfterViewInit(): void {
    setTimeout(() => this.setFocus(), 50);
  }

  private setFocus(): void {
    this.autocompleteInputElement.nativeElement.focus();
  }

  onEnter(): void {
    const selectedValue: ResourceUri = this.fieldControl.value;

    isEmpty(selectedValue) ?
      this.enterOnNoSelection.emit()
    : this.onSelection(this.getUserProfile());
  }

  private getUserProfile(): Selectable {
    const userProfilesFiltered: Selectable[] = this.values.filter((selectable) =>
      isEqual(selectable.value, this.fieldControl.value),
    );
    return isNil(userProfilesFiltered[0]) ? null : userProfilesFiltered[0];
  }

  onSelection(userProfile: Selectable): void {
    this.onOptionSelected.emit(userProfile);
  }

  keyUp(event: KeyboardEvent): void {
    if (!this.isArrow(event)) this.onKeyUp.emit();
  }

  private isArrow(event: KeyboardEvent): boolean {
    return isEqual(event.key, 'ArrowUp') || isEqual(event.key, 'ArrowDown');
  }
}

export interface Selectable {
  label: string;
  value: any;
}
