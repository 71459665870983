/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ApiRootFacade, ApiRootResource } from '@alfa-client/api-root-shared';
import {
  EMPTY_STRING,
  StateResource,
  createEmptyStateResource,
  doIfLoadingRequired,
  isNotNull,
} from '@alfa-client/tech-shared';
import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith, tap, withLatestFrom } from 'rxjs/operators';
import { VorgangFacade } from './+state/vorgang.facade';
import {
  ROUTE_PARAM_BY_VORGANG_FILTER,
  buildLinkRel,
  getSearchLinkRel,
} from './vorgang-navigation.util';
import {
  VorgangFilter,
  VorgangListResource,
  VorgangResource,
  VorgangStatistic,
  VorgangView,
} from './vorgang.model';

@Injectable({ providedIn: 'root' })
export class VorgangListService {
  static readonly SEARCH_PREVIEW_LIST_LIMIT: number = 7;

  constructor(
    private vorgangFacade: VorgangFacade,
    private apiRootFacade: ApiRootFacade,
  ) {}

  public getVorgangList(): Observable<StateResource<VorgangListResource>> {
    return this.vorgangFacade.getVorgangList().pipe(
      withLatestFrom(
        this.apiRootFacade.getApiRoot(),
        this.vorgangFacade.getVorgangFilter(),
        this.vorgangFacade.getVorgangView(),
        this.vorgangFacade.getSearchString(),
      ),
      tap(([vorgangList, apiRoot, vorgangFilter, vorgangView, searchString]) => {
        doIfLoadingRequired(vorgangList, () =>
          this.loadVorgangList(apiRoot.resource, vorgangFilter, vorgangView, searchString),
        );
      }),
      map(([vorgangList]) => vorgangList),
      startWith(createEmptyStateResource<VorgangListResource>(true)),
    );
  }

  loadVorgangList(
    apiRoot: ApiRootResource,
    vorgangFilter: VorgangFilter,
    vorgangView: VorgangView,
    searchString: string,
  ): void {
    if (vorgangView === VorgangView.SEARCH) {
      this.vorgangFacade.searchVorgaengeBy(apiRoot, searchString, getSearchLinkRel(vorgangFilter));
    } else {
      this.vorgangFacade.loadVorgangList(apiRoot, buildLinkRel(vorgangFilter, vorgangView));
    }
  }

  public loadNextPage(): void {
    this.vorgangFacade.loadNextPage();
  }

  public getVorgaenge(): Observable<VorgangResource[]> {
    return this.vorgangFacade.getVorgaenge();
  }

  public getVorgangStatistic(): Observable<StateResource<VorgangStatistic>> {
    return this.vorgangFacade.getVorgangStatistic();
  }

  public getSelectedFilterAsRouteParam(): Observable<string> {
    return this.vorgangFacade
      .getVorgangFilter()
      .pipe(map((vorgangFilter: VorgangFilter) => ROUTE_PARAM_BY_VORGANG_FILTER[vorgangFilter]));
  }

  public isVorgangViewSelected(vorgangView: VorgangView): Observable<boolean> {
    return this.vorgangFacade.isVorgangViewSelected(vorgangView);
  }

  public getVorgangViewRoutePath(vorgangView: VorgangView): Observable<string> {
    return this.vorgangFacade.getVorgangViewRoutePath(vorgangView);
  }

  public getSearchPreviewList(): Observable<StateResource<VorgangListResource>> {
    return combineLatest([
      this.apiRootFacade.getApiRoot(),
      this.vorgangFacade.getSearchPreviewList(),
      this.vorgangFacade.getSearchString(),
    ]).pipe(
      tap(([apiRoot, previewList, searchString]) => {
        if (isNotNull(apiRoot.resource) && this.shouldSearchForPreview(previewList, searchString)) {
          this.vorgangFacade.searchForPreview(searchString);
        }
      }),
      map(([, previewList]) => previewList),
      startWith(createEmptyStateResource<VorgangListResource>(true)),
    );
  }

  private shouldSearchForPreview(
    previewList: StateResource<VorgangListResource>,
    searchString: string,
  ): boolean {
    return (
      previewList.reload &&
      !previewList.loading &&
      searchString != EMPTY_STRING &&
      searchString != undefined
    );
  }

  public clearSearchPreviewList(): void {
    this.vorgangFacade.clearSearchPreviewList();
  }

  public getSearchString(): Observable<string> {
    return this.vorgangFacade.getSearchString();
  }

  public setSearchString(searchString: string): void {
    this.vorgangFacade.setSearchString(searchString);
  }

  public clearSearchString(): void {
    this.vorgangFacade.clearSearchString();
  }

  public searchForPreview(searchString: string) {
    this.vorgangFacade.searchForPreview(searchString);
  }
}
