import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'ozgdesign-testbtn',
  standalone: true,
  imports: [CommonModule],
  template: `<button
    type="button"
    class="rounded-md bg-ozgblue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ozgblue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ozgblue-800"
  >
    <ng-content></ng-content>
  </button>`,
})
export class TestbtnComponent {}
