import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormControlEditorAbstractComponent } from 'libs/design-component/src/lib/form/formcontrol-editor.abstract.component';
import { CheckboxEnumEditorItem } from './checkbox-enum-editor.model';

/**
 * Ein Checkbox, dessen Wert beim FormControl statt boolean als string aus dem enum editor item
 * abgebildet wird.
 */
@Component({
  selector: 'ozgcloud-checkbox-enum-editor',
  templateUrl: './checkbox-enum-editor.component.html',
  styleUrls: ['./checkbox-enum-editor.component.scss'],
})
export class CheckboxEnumEditorComponent
  extends FormControlEditorAbstractComponent
  implements OnInit
{
  @Input() label: string;
  @Input() defaultItem: CheckboxEnumEditorItem;
  @Input() checkedItem: CheckboxEnumEditorItem;
  @Input() uncheckedItem: CheckboxEnumEditorItem;

  public selected: boolean;

  override ngOnInit(): void {
    this.selected = this.defaultItem === this.checkedItem;
    this.writeValue(this.defaultItem.type);
  }

  setSelection(event: MatCheckboxChange): void {
    this.writeValue(event.checked ? this.checkedItem.type : this.uncheckedItem.type);
  }
}
