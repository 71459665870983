/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  createEmptyStateResource,
  doIfLoadingRequired,
  StateResource,
} from '@alfa-client/tech-shared';
import { UserProfileService } from '@alfa-client/user-profile-shared';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, startWith, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserSettingsFacade } from './+state/user-settings.facade';
import { UserSettings, UserSettingsResource } from './user-settings.model';

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  constructor(
    private userSettingsFacade: UserSettingsFacade,
    private userProfileService: UserProfileService,
  ) {}

  public getUserSettings(): Observable<StateResource<UserSettingsResource>> {
    return combineLatest([
      this.userProfileService.getCurrentUser(),
      this.userSettingsFacade.getUserSettings(),
    ]).pipe(
      tap(([userProfile, userSettings]) =>
        doIfLoadingRequired(userSettings, () => {
          if (userProfile.resource) {
            this.userSettingsFacade.loadUserSettings(userProfile.resource);
          }
        }),
      ),
      map(([, userSettings]) => userSettings),
      startWith(createEmptyStateResource<UserSettingsResource>(true)),
    );
  }

  public setUserSettings(userSettings: UserSettings): void {
    this.userSettingsFacade.setUserSettings(userSettings);
  }
}
