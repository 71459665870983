/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { getBaseUrl } from '@alfa-client/tech-shared';
import { InjectionToken } from '@angular/core';
import { Environment } from './environment.model';

export const ENVIRONMENT_CONFIG = new InjectionToken('environmentConfig');

export function getEnvironmentFactory(): Environment {
  // @ts-ignore
  return window['__env__'];
}

export async function loadEnvironment(url?: string): Promise<any> {
  const envUrl = url ? url : `${getBaseUrl()}api/environment`;
  const headers = new Headers({ 'X-Client': 'web' });

  return window
    .fetch(envUrl, { headers })
    .then((response) => response.json())
    .then((env) => {
      // @ts-ignore
      window['__env__'] = env;
      return env;
    });
}
