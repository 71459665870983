/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  ApiErrorAction,
  TypedActionCreator,
  TypedActionCreatorWithProps,
} from '@alfa-client/tech-shared';
import { createAction, props } from '@ngrx/store';
import { ApiRootResource } from '../api-root.model';

export interface ApiRootProps {
  apiRoot: ApiRootResource;
}

export const loadApiRoot: TypedActionCreator = createAction('[ApiRoot] Load');
export const loadApiRootSuccess: TypedActionCreatorWithProps<ApiRootProps> = createAction(
  '[ApiRoot] Load Success',
  props<ApiRootProps>(),
);
export const loadApiRootFailure: TypedActionCreatorWithProps<ApiErrorAction> = createAction(
  '[ApiRoot] Load Failure',
  props<ApiErrorAction>(),
);
