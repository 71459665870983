import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';

import { IconVariants, iconVariants } from '../iconVariants';

@Component({
  selector: 'ods-person-icon',
  standalone: true,
  imports: [NgClass],
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'stroke-gray-600', class)]"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.4 22.8v-2.4a4.8 4.8 0 00-4.8-4.8H8.4a4.8 4.8 0 00-4.8 4.8v2.4m8.4-12a4.8 4.8 0 100-9.6 4.8 4.8 0 000 9.6z"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      fill="none"
    />
  </svg>`,
})
export class PersonIconComponent {
  @Input() size: IconVariants['size'] = 'small';
  @Input() class: string = undefined;

  public readonly iconVariants = iconVariants;
  public readonly twMerge = twMerge;
}
