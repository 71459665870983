import { Component, Input } from '@angular/core';
import { StateResource } from '@alfa-client/tech-shared';
import { Resource } from '@ngxp/rest';

@Component({
  selector: 'ozgcloud-button-content',
  templateUrl: './ozgcloud-button-content.component.html',
  styleUrls: ['./ozgcloud-button-content.component.scss'],
})
export class OzgcloudButtonContentComponent {
  @Input() icon: string;
  @Input() svgIcon: string;
  @Input() text: string;
  @Input() stateResource: StateResource<Resource>;
  @Input() showSpinner: boolean = false;
  @Input() isDisabled: boolean = false;
}
