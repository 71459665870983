/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { BinaryFileListResource } from '@alfa-client/binary-file-shared';
import { StateResource } from '@alfa-client/tech-shared';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { POSTFACH_FEATURE_KEY, PostfachState } from './postfach.reducer';

export const getPostfachState: MemoizedSelector<object, PostfachState> =
  createFeatureSelector<PostfachState>(POSTFACH_FEATURE_KEY);

export const isDownloadPdfInProgress: MemoizedSelector<PostfachState, boolean> = createSelector(
  getPostfachState,
  (state: PostfachState) => state.isDownloadPdfInProgress,
);
export const attachmentList: MemoizedSelector<
  PostfachState,
  StateResource<BinaryFileListResource>
> = createSelector(getPostfachState, (state: PostfachState) => state.attachmentList);
