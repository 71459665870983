import { Injectable } from '@angular/core';
import { ApiRootLinkRel, ApiRootProps } from '@alfa-client/api-root-shared';
import { getApiErrorFromHttpErrorResponse } from '@alfa-client/tech-shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { hasLink } from '@ngxp/rest';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { HintRepository } from './hint.repository';

import * as ApiRootActions from '../../../../api-root-shared/src/lib/+state/api-root.actions';
import * as HintActions from '../../../../hint-shared/src/lib/+state/hint.actions';

@Injectable()
export class HintEffects {
  constructor(
    private readonly actions$: Actions,
    private repository: HintRepository,
  ) {}

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiRootActions.loadApiRootSuccess),
      filter((props: ApiRootProps) => hasLink(props.apiRoot, ApiRootLinkRel.HINTS)),
      switchMap((props: ApiRootProps) =>
        this.repository.loadAll(props.apiRoot).pipe(
          map((hintList) => HintActions.loadHintListSuccess({ hintList })),
          catchError((apiError) =>
            of(
              HintActions.loadHintListFailed({
                apiError: getApiErrorFromHttpErrorResponse(apiError),
              }),
            ),
          ),
        ),
      ),
    ),
  );
}
