<a
  [routerLink]="linkTo"
  mat-icon-button
  data-test-id="back-button"
  class="back-button"
  [attr.aria-label]="label"
  [matTooltip]="label"
>
  <mat-icon>arrow_back</mat-icon>
</a>
