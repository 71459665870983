/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRootLinkRel, ApiRootResource } from '@alfa-client/api-root-shared';
import { BinaryFileListResource } from '@alfa-client/binary-file-shared';
import {
  BlobWithFileName,
  ContentType,
  EMPTY_STRING,
  GetRequestOptions,
  HttpHeader,
  SkipInterceptor,
  buildBlobWithFileName,
  isNotUndefined,
} from '@alfa-client/tech-shared';
import { Resource, ResourceFactory, ResourceUri, getUrl } from '@ngxp/rest';
import { Observable, map } from 'rxjs';
import { VorgangListLinkRel, VorgangWithEingangLinkRel } from './../vorgang.linkrel';
import { VorgangListResource, VorgangWithEingangResource } from './../vorgang.model';

@Injectable({ providedIn: 'root' })
export class VorgangRepository {
  static SEARCH_PARAM: string = 'searchBy';
  static LIMIT_PARAM: string = 'limit';

  static GET_VORGANG: string = 'getVorgang';

  constructor(
    private resourceFactory: ResourceFactory,
    private httpClient: HttpClient,
  ) {}

  public loadVorgangList(
    apiRootResource: ApiRootResource,
    linkRel: string,
  ): Observable<VorgangListResource> {
    return this.resourceFactory.from(apiRootResource).get(linkRel);
  }

  public getNextVorgangListPage(
    vorgangListResource: VorgangListResource,
  ): Observable<VorgangListResource> {
    return this.resourceFactory.from(vorgangListResource).get(VorgangListLinkRel.NEXT);
  }

  public loadMeineVorgaengeList(apiRootResource: ApiRootResource): Observable<VorgangListResource> {
    const url: URL = this.getUrlWithoutParameter(apiRootResource, ApiRootLinkRel.MEINE_VORGAENGE);
    return this.resourceFactory.fromId(url.href).get();
  }

  public getVorgang(vorgangWithEingangUrl: ResourceUri): Observable<VorgangWithEingangResource> {
    return this.resourceFactory.fromId(vorgangWithEingangUrl).get();
  }

  public getAttachments(vorgang: VorgangWithEingangResource): Observable<BinaryFileListResource> {
    return this.resourceFactory.from(vorgang).get(VorgangWithEingangLinkRel.ATTACHMENTS);
  }

  public getRepresentations(
    vorgang: VorgangWithEingangResource,
  ): Observable<BinaryFileListResource> {
    return this.resourceFactory.from(vorgang).get(VorgangWithEingangLinkRel.REPRESENTATIONS);
  }

  @SkipInterceptor()
  public searchVorgaengeBy(
    apiRootResource: ApiRootResource,
    searchBy: string,
    linkRel: string,
    limit?: number,
  ): Observable<VorgangListResource> {
    return this.resourceFactory
      .fromId(this.buildSearchByUrl(apiRootResource, searchBy, linkRel, limit))
      .get();
  }

  buildSearchByUrl(
    apiRootResource: ApiRootResource,
    searchBy: string,
    linkRel: string,
    limit?: number,
  ): ResourceUri {
    //TODO sollte mal bei ngxp eingebaut werden und geprüft werden, dass nur erwartete Paramter befüllt werden
    const url: URL = this.getUrlWithoutParameter(apiRootResource, linkRel);
    url.searchParams.set(VorgangRepository.SEARCH_PARAM, searchBy);
    if (isNotUndefined(limit))
      url.searchParams.set(VorgangRepository.LIMIT_PARAM, limit.toString());
    return url.href;
  }

  getUrlWithoutParameter(resource: Resource, linkRel: string): URL {
    return new URL(getUrl(resource, linkRel).replace(/{&[\w,]*}$/, EMPTY_STRING));
  }

  public export(vorgangWithEingang: VorgangWithEingangResource): Observable<BlobWithFileName> {
    return this.httpClient
      .get<
        HttpResponse<Blob>
      >(getUrl(vorgangWithEingang, VorgangWithEingangLinkRel.EXPORT), this.buildRequestOptions())
      .pipe(map(buildBlobWithFileName));
  }

  buildRequestOptions(): GetRequestOptions {
    let headers = new HttpHeaders();
    headers = headers.set(HttpHeader.ACCEPT, ContentType.APPLICATION_OCTET_STREAM);
    return <GetRequestOptions>{ headers, responseType: 'blob' as 'json', observe: 'response' };
  }
}
