import { Resource } from '@ngxp/rest';
import { Observable } from 'rxjs';
import { ResourceServiceConfig } from './resource.model';
import { ResourceRepository } from './resource.repository';
import { ResourceService } from './resource.service';

export class ApiResourceService<B extends Resource, T extends Resource> extends ResourceService<B, T> {
  constructor(
    protected config: ResourceServiceConfig<B>,
    protected repository: ResourceRepository,
  ) {
    super(config, repository);
  }

  doSave(resource: T, toSave: unknown): Observable<T> {
    return <Observable<T>>this.repository.save({
      resource,
      linkRel: this.config.edit.linkRel,
      toSave,
    });
  }

  doPatch(resource: T, toPatch: unknown): Observable<T> {
    return <Observable<T>>this.repository.patch({
      resource,
      linkRel: this.config.edit.linkRel,
      toSave: toPatch,
    });
  }
}
