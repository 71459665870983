import { Resource } from '@ngxp/rest';

export interface InstantSearchResult<T extends Resource> {
  title: string;
  description: string;
  data?: T;
}

export interface InstantSearchQuery {
  searchBy: string;
}
