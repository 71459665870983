/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ApiRootService } from '@alfa-client/api-root-shared';
import { BinaryFileListResource } from '@alfa-client/binary-file-shared';
import {
  CommandOrder,
  CommandResource,
  CommandService,
  CreateCommandProps,
  getEffectedResourceUrl,
} from '@alfa-client/command-shared';
import { ENVIRONMENT_CONFIG, Environment } from '@alfa-client/environment-shared';
import { NavigationService } from '@alfa-client/navigation-shared';
import {
  StateResource,
  createEmptyStateResource,
  doIfLoadingRequired,
  isNotNull,
} from '@alfa-client/tech-shared';
import { Inject, Injectable } from '@angular/core';
import { ResourceUri, hasLink } from '@ngxp/rest';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, startWith, tap, withLatestFrom } from 'rxjs/operators';
import { VorgangFacade } from './+state/vorgang.facade';
import { buildLinkRelFromPathSegments } from './vorgang-navigation.util';
import { VorgangWithEingangLinkRel } from './vorgang.linkrel';
import { AdditionalActions, VorgangWithEingangResource } from './vorgang.model';
import { createAssignUserCommand, createProcessVorgangCommand } from './vorgang.util';

@Injectable({ providedIn: 'root' })
export class VorgangService {
  public static readonly VORGANG_WITH_EINGANG_URL: string = 'vorgangWithEingangUrl';

  constructor(
    private navigationService: NavigationService,
    private facade: VorgangFacade,
    private apiRootService: ApiRootService,
    private commandService: CommandService,
    @Inject(ENVIRONMENT_CONFIG) private envConfig: Environment,
  ) {}

  public getVorgangWithEingang(): Observable<StateResource<VorgangWithEingangResource>> {
    return combineLatest([
      this.facade.getVorgangWithEingang(),
      this.apiRootService.getApiRoot(),
    ]).pipe(
      tap(([vorgangWithEingang, apiRoot]) => {
        if (isNotNull(apiRoot.resource))
          doIfLoadingRequired(vorgangWithEingang, () =>
            this.facade.loadVorgangWithEingang(this.getVorgangWithEingangUri()),
          );
      }),
      map(([vorgangWithEingang]) => vorgangWithEingang),
      startWith(createEmptyStateResource<VorgangWithEingangResource>(true)),
    );
  }

  public getAttachments(): Observable<StateResource<BinaryFileListResource>> {
    return this.facade.getAttachmentList().pipe(
      withLatestFrom(this.facade.getVorgangWithEingang()),
      tap(([attachmentList, vorgangWithEingang]) =>
        doIfLoadingRequired(attachmentList, () =>
          this.facade.loadAttachmentList(vorgangWithEingang.resource),
        ),
      ),
      map(([attachmentList]) => attachmentList),
      startWith(createEmptyStateResource<BinaryFileListResource>(true)),
    );
  }

  public getRepresentations(): Observable<StateResource<BinaryFileListResource>> {
    return this.facade.getRepresentationList().pipe(
      withLatestFrom(this.facade.getVorgangWithEingang()),
      tap(([representationList, vorgangWithEingang]) =>
        doIfLoadingRequired(representationList, () =>
          this.facade.loadRepresentationList(vorgangWithEingang.resource),
        ),
      ),
      map(([representationList]) => representationList),
      startWith(createEmptyStateResource<BinaryFileListResource>(true)),
    );
  }

  public clearVorgang(): void {
    this.facade.clearVorgangWithEingang();
  }

  public setPendingSendPostfachMailCommand(command: StateResource<CommandResource>) {
    this.facade.setPendingSendPostfachMailSingleCommand(command);
  }
  public setPendingSendPostfachMailSingleCommandLoading(): void {
    this.facade.setPendingSendPostfachMailSingleCommandLoading();
  }
  public getPendingSendPostfachMailCommand(): Observable<StateResource<CommandResource>> {
    return this.facade.getSendPostfachNachrichtPendingCommand();
  }

  public setPendingForwardSingleCommandLoading(): void {
    this.facade.setForwardSingleCommandLoading();
  }
  public getPendingForwardCommand(): Observable<StateResource<CommandResource>> {
    return this.facade.getForwardPendingCommand();
  }
  public setPendingForwardSingleCommand(command: StateResource<CommandResource>): void {
    this.facade.setForwardSingleCommand(command);
  }

  public reloadCurrentVorgang(): void {
    this.facade.loadVorgangWithEingang(this.getVorgangWithEingangUri());
  }

  public reloadCurrentVorgangWithAddtionalActions(additionalActions: AdditionalActions): void {
    this.facade.loadVorgangWithEingangWithAdditionalActions(
      this.getVorgangWithEingangUri(),
      additionalActions,
    );
  }

  getVorgangWithEingangUri(): ResourceUri {
    return this.navigationService.getDecodedParam(VorgangService.VORGANG_WITH_EINGANG_URL);
  }

  public assignUser(userUri: ResourceUri): Observable<StateResource<CommandResource>> {
    this.facade.initAssignUser();

    return this.getAssignUserCommand().pipe(
      withLatestFrom(this.facade.getVorgangWithEingang()),
      tap(([assignCommand, vorgangWithEingang]) => {
        if (assignCommand.reload && !assignCommand.loading) {
          this.facade.assignUser(vorgangWithEingang.resource, createAssignUserCommand(userUri));
        }
      }),
      map(([assignCommand]) => assignCommand),
    );
  }

  getAssignUserCommand(): Observable<StateResource<CommandResource>> {
    return this.facade.getAssignUserCommand();
  }

  public reloadVorgang(commandResource: CommandResource): void {
    this.facade.loadVorgangWithEingang(getEffectedResourceUrl(commandResource));
  }

  public getBackButtonUrl(): Observable<string> {
    return this.facade.getBackButtonUrl();
  }

  public canNavigateToPathSegements(path: string): Observable<boolean> {
    const pathSegments: string[] = path.substring(1).split('/');
    const linkRel: string = buildLinkRelFromPathSegments(pathSegments);

    return this.apiRootService.getApiRoot().pipe(
      filter((apiRoot) => isNotNull(apiRoot.resource)),
      map((apiRoot) => hasLink(apiRoot.resource, linkRel)),
    );
  }

  public getVorgangExport(): Observable<StateResource<boolean>> {
    return this.facade.getVorgangExport();
  }

  public export(vorgangWithEingang: VorgangWithEingangResource): void {
    this.facade.export(vorgangWithEingang);
  }

  public processVorgang(
    vorgangWithEingang: VorgangWithEingangResource,
  ): Observable<StateResource<CommandResource>> {
    return this.commandService.createCommandByProps(
      this.createProcessVorgangCommandProps(vorgangWithEingang),
    );
  }

  private createProcessVorgangCommandProps(
    vorgangWithEingang: VorgangWithEingangResource,
  ): CreateCommandProps {
    return {
      resource: vorgangWithEingang,
      linkRel: VorgangWithEingangLinkRel.PROCESS_VORGANG,
      command: createProcessVorgangCommand(this.envConfig.processorNames),
      snackBarMessage: 'Vorgang vorprüfen erfolgreich.',
    };
  }

  public setAktenzeichen(
    vorgang: VorgangWithEingangResource,
    aktenzeichen: string,
  ): Observable<StateResource<CommandResource>> {
    const createCommand = { order: CommandOrder.SET_AKTENZEICHEN, body: { aktenzeichen } };
    return this.commandService.createCommand(
      vorgang,
      VorgangWithEingangLinkRel.SET_AKTENZEICHEN,
      createCommand,
    );
  }
}
