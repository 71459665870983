import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ods-search-result-layer',
  standalone: true,
  imports: [CommonModule],
  template: `<div
    [ngClass]="[
      'rounded-lg border border-primary-600/50 bg-background-50 shadow-lg',
      containerClass,
    ]"
  >
    <ng-content select="[header]" />
    <ul role="list">
      <ng-content />
    </ul>
  </div>`,
})
export class SearchResultLayerComponent {
  @Input() containerClass: string = '';
}
