<div class="relative flex h-full flex-1">
  <input
    type="radio"
    class="peer absolute left-0 top-0 z-0 h-0 w-0 opacity-0"
    id="{{ name }}_{{ value }}"
    name="{{ name }}"
    attr.name="{{ name }}"
    formControlName="{{ name }}"
    value="{{ value }}"
  />
  <label
    for="{{ name }}_{{ value }}"
    [ngClass]="getClass(variant)"
    class="z-10 flex flex-1 cursor-pointer items-center justify-center gap-2 rounded-lg text-center text-base peer-focus-visible:outline peer-focus-visible:outline-2 peer-focus-visible:outline-offset-2 peer-focus-visible:outline-ozgblue-800"
  >
    <ng-content />
    <p class="h-fit" [ngClass]="{ 'w-full': fullWidthText }">{{ label }}</p>
  </label>
</div>
