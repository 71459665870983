import { createEmptyStateResource } from '@alfa-client/tech-shared';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { isUndefined } from 'lodash-es';
import { CommandOrder } from '../command.model';
import { COMMAND_FEATURE_KEY, CommandState } from './command.reducer';

const getCommandState: MemoizedSelector<object, CommandState> =
  createFeatureSelector<CommandState>(COMMAND_FEATURE_KEY);

export const commandByOrderMap: MemoizedSelector<CommandState, any> = createSelector(
  getCommandState,
  (state: CommandState) => state.commandByOrderMap,
);
export const commandByOrder = (order: CommandOrder) =>
  createSelector(commandByOrderMap, (commandByOrderMapInState: any) =>
    isUndefined(commandByOrderMapInState[order]) ?
      createEmptyStateResource()
    : commandByOrderMapInState[order],
  );
