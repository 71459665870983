import { ResourceRedirectService } from '@alfa-client/resource-redirect-shared';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ResourceUri } from '@ngxp/rest';

@Component({
  standalone: true,
  template: '',
})
export class ResourceRedirectComponent implements OnInit {
  private readonly PARAM_URI: string = 'uri';

  constructor(
    private route: ActivatedRoute,
    private resourceRedirectService: ResourceRedirectService,
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((queryParams: ParamMap) => {
      const resourceUri: ResourceUri = queryParams.get(this.PARAM_URI);
      if (resourceUri) {
        this.resourceRedirectService.redirectToResource(resourceUri);
      }
    });
  }
}
