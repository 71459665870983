import { Component, Input } from '@angular/core';

@Component({
  selector: 'ozgcloud-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  @Input() label: string;
  @Input() linkTo: string | any[];
}
