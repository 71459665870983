<!--

    Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<ozgcloud-spinner [stateResource]="currentUserResource" [diameter]="30">
  <button
    mat-icon-button
    [matMenuTriggerFor]="accountMenu.matMenu"
    class="big-button"
    data-test-id="user-icon-button"
    [attr.aria-label]="buttonLabel"
  >
    <alfa-user-icon [userProfileStateResource]="currentUserResource"></alfa-user-icon>
  </button>
</ozgcloud-spinner>

<ozgcloud-menu #accountMenu data-test-id="account-menu">
  <button mat-menu-item (click)="logoutEmitter.emit()" data-test-id="logout-button">
    <mat-icon>logout</mat-icon>
    <span class="text-base">Abmelden</span>
  </button>
</ozgcloud-menu>
