/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ApiRootModule } from '@alfa-client/api-root-shared';
import { AppSharedModule } from '@alfa-client/app-shared';
import { BuildInfoComponent } from '@alfa-client/common';
import { EnvironmentModule } from '@alfa-client/environment-shared';
import { HintSharedModule } from '@alfa-client/hint-shared';
import { NavigationModule } from '@alfa-client/navigation';
import { OzgCloudUrlSerializer } from '@alfa-client/navigation-shared';
import { ResourceRedirectComponent } from '@alfa-client/resource-redirect';
import { UiModule } from '@alfa-client/ui';
import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, UrlSerializer } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

registerLocaleData(localeDe);

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@alfa-client/vorgang').then((m) => m.VorgangModule),
  },
  {
    path: 'resources',
    component: ResourceRedirectComponent,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled',
      onSameUrlNavigation: 'reload',
    }),
    UiModule,
    EnvironmentModule,
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
      },
    }),
    ApiRootModule,
    NavigationModule,
    BuildInfoComponent,
    AppSharedModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot([]),
    environment.production ? [] : StoreDevtoolsModule.instrument({ connectInZone: true }),
    StoreRouterConnectingModule.forRoot(),
    // Workaround: load any module depending on ApiRootActions.loadApiRootSuccess
    // TODO Check if a future NgRx version will solve Lazy Loading + Effects.forFeature()
    HintSharedModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: UrlSerializer, useClass: OzgCloudUrlSerializer },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
