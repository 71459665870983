import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'ods-checkbox',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  template: `
    <div>
      <div class="flex items-start gap-3 text-start">
        <input
          type="checkbox"
          class="disabled:border-disabled-dark disabled:bg-disabled peer relative box-border size-5 shrink-0 appearance-none rounded-sm
        border bg-whitetext outline outline-2 outline-offset-2 outline-transparent
        hover:border-2 focus-visible:border-background-200 disabled:hover:border"
          [ngClass]="
            hasError ?
              'border-error hover:border-error focus-visible:outline-error'
            : 'border-primary hover:border-primary-hover focus-visible:outline-focus'
          "
          [value]="value"
          [checked]="fieldControl.value"
          [attr.id]="inputId"
          [disabled]="disabled"
        />
        <label class="leading-5 text-text" [attr.for]="inputId">{{ label }}</label>
        <svg
          viewBox="0 0 12 9"
          xmlns="http://www.w3.org/2000/svg"
          class="pointer-events-none absolute hidden size-5 p-1 outline-none peer-checked:block"
          [ngClass]="hasError ? 'fill-error' : 'fill-primary focus-visible:fill-focus'"
        >
          <path
            d="M3.81353 7.10067L0.968732 4.30201L0 5.24832L3.81353
          9L12 0.946309L11.0381 0L3.81353 7.10067Z"
          />
        </svg>
      </div>
      <ng-content select="[error]" />
    </div>
  `,
})
export class CheckboxComponent {
  @Input() fieldControl: FormControl = new FormControl(false);
  @Input() value: string;
  @Input() inputId: string;
  @Input() label: string;
  @Input() disabled: boolean = false;
  @Input() hasError: boolean = false;
}
