import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'ods-list-item',
  standalone: true,
  imports: [CommonModule, RouterLink],
  template: `<li>
    <a
      [routerLink]="path"
      data-test-class="list-item-link"
      class="flex flex-col items-start justify-between gap-6 border-primary-600/50 px-6 py-4 hover:bg-background-150 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus lg:flex-row"
      ><ng-content
    /></a>
  </li>`,
  styles: [':host { @apply block w-full }'],
})
export class ListItemComponent {
  @Input() path: string;
}
