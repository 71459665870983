import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { CloseIconComponent } from '../icons/close-icon/close-icon.component';
import { StampIconComponent } from '../icons/stamp-icon/stamp-icon.component';

@Component({
  selector: 'ods-bescheid-status-text',
  standalone: true,
  imports: [CommonModule, StampIconComponent, CloseIconComponent],
  template: ` <p class="flex gap-2 text-base font-medium text-text">
    <span class="flex items-center gap-2" *ngIf="bewilligt"
      ><ods-stamp-icon size="medium" class="fill-bewilligt" />Bewilligt am {{ dateText }}</span
    >
    <span class="flex items-center gap-2" *ngIf="!bewilligt"
      ><ods-close-icon class="fill-abgelehnt" />Abgelehnt am
      {{ dateText }}
    </span>
    <span
      *ngIf="hasBescheidDraft"
      class="flex items-center text-error"
      data-test-class="bescheid-status-draft"
      >(Entwurf)</span
    >
  </p>`,
})
export class BescheidStatusTextComponent {
  @Input({ required: true }) dateText: string = '';
  @Input() bewilligt: boolean = false;
  @Input() hasBescheidDraft: boolean = false;
}
