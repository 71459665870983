import { Component, Input } from '@angular/core';

@Component({
  selector: 'alfa-open-documentation-button',
  templateUrl: './open-documentation-button.component.html',
  styleUrls: ['./open-documentation-button.component.scss'],
})
export class OpenDocumentationButtonComponent {
  @Input() url: string;
}
