import { Injectable } from '@angular/core';
import { ApiRootLinkRel, ApiRootResource } from '@alfa-client/api-root-shared';
import { ResourceFactory } from '@ngxp/rest';
import { Observable } from 'rxjs';
import { HintListResource } from '../hint.model';

@Injectable()
export class HintRepository {
  constructor(private resourceFactory: ResourceFactory) {}

  public loadAll(apiRoot: ApiRootResource): Observable<HintListResource> {
    return this.resourceFactory.from(apiRoot).get(ApiRootLinkRel.HINTS);
  }
}
