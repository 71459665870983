/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ApiError, ApiErrorAction, TypedActionCreatorWithProps } from '@alfa-client/tech-shared';
import { createAction, props, Action } from '@ngrx/store';
import { Resource, ResourceUri } from '@ngxp/rest';
import { BinaryFileListResource } from '../binary-file.model';

export interface DownloadBinaryFileAsPdfAction {
  uri: ResourceUri;
  fileName: string;
  successAction: () => Action<string>;
  failureAction: (apiError: ApiError) => ApiErrorAction & Action<string>;
}

export const downloadPdf: TypedActionCreatorWithProps<DownloadBinaryFileAsPdfAction> = createAction(
  '[BinaryFile] Download pdf file',
  props<DownloadBinaryFileAsPdfAction>(),
);

export interface SaveFileProps {
  fileData: Blob;
  fileName: string;
}

export const saveFile: TypedActionCreatorWithProps<SaveFileProps> = createAction(
  '[BinaryFile/API] Save file',
  props<SaveFileProps>(),
);

export interface LoadBinaryFileListSuccessProps {
  binaryFileList: BinaryFileListResource;
}
export interface LoadBinaryFileListProps {
  resource: Resource;
  linkRel: string;
  successAction: (
    binaryFileList: BinaryFileListResource,
  ) => LoadBinaryFileListSuccessProps & Action<string>;
  failureAction: (apiError: ApiError) => ApiErrorAction & Action<string>;
}

export const loadBinaryFileList: TypedActionCreatorWithProps<LoadBinaryFileListProps> =
  createAction('[BinaryFile] Load BinaryFile List', props<LoadBinaryFileListProps>());
