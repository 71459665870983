/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, Input, OnInit } from '@angular/core';
import {
  createEmptyStateResource,
  isNotNil,
  replacePlaceholders,
  StateResource,
} from '@alfa-client/tech-shared';
import { UserProfileResource } from '@alfa-client/user-profile-shared';

@Component({
  selector: 'alfa-text-with-user-name-tooltip',
  templateUrl: './text-with-user-name-tooltip.component.html',
  styleUrls: ['./text-with-user-name-tooltip.component.scss'],
})
export class TextWithUserNameTooltipComponent implements OnInit {
  @Input() tooltipTemplate: string;
  @Input() text: string;
  @Input() userProfile: StateResource<UserProfileResource> = createEmptyStateResource();

  tooltip: string;

  ngOnInit(): void {
    this.tooltip = replacePlaceholders(this.tooltipTemplate, this.getPlaceholderValues());
  }

  private getPlaceholderValues() {
    if (isNotNil(this.userProfile.resource)) {
      return {
        firstName: this.userProfile.resource.firstName,
        lastName: this.userProfile.resource.lastName,
      };
    }

    return { firstName: undefined, lastName: undefined };
  }
}
