import { Component, Input } from '@angular/core';

@Component({
  selector: 'ozgcloud-svgicon-big',
  templateUrl: './ozgcloud-svgicon-big.component.html',
  styleUrls: ['./ozgcloud-svgicon-big.component.scss'],
})
export class OzgcloudSvgIconBigComponent {
  @Input() svgIcon: string;
}
