import { StateResource } from '@alfa-client/tech-shared';
import { Observable, of, switchMap, tap } from 'rxjs';
import { CommandResource } from './command.model';
import { isSuccessfulDone } from './command.util';

export function tapOnCommandSuccessfullyDone(
  runnable: (commandStateResource: StateResource<CommandResource>) => void,
): (
  source: Observable<StateResource<CommandResource>>,
) => Observable<StateResource<CommandResource>> {
  return (
    source: Observable<StateResource<CommandResource>>,
  ): Observable<StateResource<CommandResource>> => {
    return source.pipe(
      tap((commandStateResource: StateResource<CommandResource>) => {
        if (isSuccessfulDone(commandStateResource.resource)) runnable(commandStateResource);
      }),
    );
  };
}

export function switchMapCommandSuccessfullyDone(
  runnable: (command: StateResource<CommandResource>) => Observable<StateResource<CommandResource>>,
): (
  source: Observable<StateResource<CommandResource>>,
) => Observable<StateResource<CommandResource>> {
  return (
    source: Observable<StateResource<CommandResource>>,
  ): Observable<StateResource<CommandResource>> => {
    return source.pipe(
      switchMap((commandStateResource: StateResource<CommandResource>) => {
        if (isSuccessfulDone(commandStateResource.resource)) {
          return runnable(commandStateResource);
        } else {
          return of(commandStateResource);
        }
      }),
    );
  };
}
