/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, Input } from '@angular/core';
import {
  ApiError,
  StateResource,
  createEmptyStateResource,
  hasStateResourceError,
  isServiceUnavailableMessageCode,
} from '@alfa-client/tech-shared';
import { UserProfileResource } from '@alfa-client/user-profile-shared';
import { VorgangWithEingangLinkRel, VorgangWithEingangResource } from '@alfa-client/vorgang-shared';
import { Resource } from '@ngxp/rest';

@Component({
  selector: 'alfa-user-profile-in-vorgang',
  templateUrl: './user-profile-in-vorgang.component.html',
  styleUrls: ['./user-profile-in-vorgang.component.scss'],
})
export class UserProfileInVorgangComponent {
  @Input() vorgang: VorgangWithEingangResource;
  @Input() userProfile: StateResource<UserProfileResource> =
    createEmptyStateResource<UserProfileResource>();

  readonly vorgangLinkRel = VorgangWithEingangLinkRel;

  public isUserServiceAvailable(stateResource: StateResource<Resource>): boolean {
    if (hasStateResourceError(stateResource) && isServiceUnavailableMessageCode(<ApiError>stateResource.error)) {
      return false;
    }
    return true;
  }
}
