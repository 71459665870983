import { ApiError, EMPTY_STRING, getMessageForIssue, Issue } from '@alfa-client/tech-shared';
import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';

@Pipe({ name: 'convertApiErrorToErrorMessages' })
export class ConvertApiErrorToErrorMessagesPipe implements PipeTransform {
  transform(value: ApiError) {
    if (isNil(value)) {
      return [];
    }
    return value.issues.map((issue: Issue) => getMessageForIssue(EMPTY_STRING, issue));
  }
}
