/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { formatDate } from '@angular/common';
import { isNil } from 'lodash-es';

import * as dateFns from 'date-fns';

export function formatForDatabase(date: Date): string {
  if (isNil(date)) {
    return date;
  }
  const dateFormat: string = 'yyyy-MM-dd';
  return formatDate(date, dateFormat, 'de');
}

export function formatFullDateWithTimeWithoutSeconds(date: Date): string {
  return formatDate(date, 'dd.MM.yy HH:mm', 'de');
}

export function formatFullDateWithTimeAndDay(date: Date): string {
  return formatDate(date, 'EEEE, dd.MM.yyyy, HH:mm:ss', 'de');
}

export function formatFullDate(date: Date): string {
  return formatDate(date, 'dd.MM.yyyy', 'de');
}

export function formatHourMinute(date: Date): string {
  return formatDate(date, 'hh:mm', 'de');
}

export function formatWithoutYear(date: Date): string {
  return formatDate(date, 'dd. MMM', 'de');
}

export function formatFullDateWithoutSeperator(date: Date): string {
  return formatDate(date, 'yyyyMMdd', 'de');
}

export function formatDateWithoutYearWithTime(date: Date): string {
  return formatDate(date, 'dd. MMM HH:mm', 'de');
}

export function formatToPrettyDate(date: Date): string {
  date = dateFns.isValid(date) ? date : dateFns.parseISO(date as unknown as string);

  if (dateFns.isSameYear(new Date(), date)) {
    return formatWithoutYear(date);
  }

  return formatFullDate(date);
}

export function createFutureDate(todayPlusDays: number): Date {
  const date = new Date();
  date.setDate(date.getDate() + todayPlusDays);

  return date;
}

export function isParsableToDate(date: string): boolean {
  return !isNaN(Date.parse(date));
}

export function isISODateInPast(isoDate: string): boolean {
  const date: Date = dateFns.parseISO(isoDate);

  return dateFns.isPast(date);
}

export function sortByGermanDateStr<T>(entries: T[], getCompareField: (entry: T) => string): T[] {
  return entries.sort((toCompare: T, compareWith: T) => {
    return (
      convertGermanDateString(getCompareField(compareWith)).getTime() -
      convertGermanDateString(getCompareField(toCompare)).getTime()
    );
  });
}

function convertGermanDateString(dateStr: string): Date {
  return new Date(dateStr.replace(/(.*)\.(.*)\.(.*)/, '$3-$2-$1'));
}

// Workaround, solange MatDatepicker genutzt wird.
export function add2000Years(date: Date): Date {
  const year: number = date.getFullYear();
  if (year.toString().length !== 2) {
    return date;
  }

  return dateFns.addYears(date, 2000);
}
