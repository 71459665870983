import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'ods-bescheid-wrapper',
  standalone: true,
  imports: [CommonModule],
  template: ` <article class="flex flex-col gap-4 rounded-lg bg-background-150 p-4">
    <ng-content></ng-content>
  </article>`,
})
export class BescheidWrapperComponent {}
