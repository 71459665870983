/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRootFacade } from '@alfa-client/api-root-shared';
import {
  BinaryFileListResource,
  LoadBinaryFileListProps,
  loadBinaryFileList,
} from '@alfa-client/binary-file-shared';
import {
  CommandListResource,
  LoadCommandListProps,
  loadCommandList,
} from '@alfa-client/command-shared';
import {
  ApiError,
  BlobWithFileName,
  getApiErrorFromHttpErrorResponse,
  isNotUndefined,
  isServiceUnavailable,
} from '@alfa-client/tech-shared';
import { SnackBarService } from '@alfa-client/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store, Action } from '@ngrx/store';
import { hasLink } from '@ngxp/rest';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { VorgangListService } from '../vorgang-list.service';
import { getSearchLinkRel } from '../vorgang-navigation.util';
import { VorgangWithEingangLinkRel } from '../vorgang.linkrel';
import { VorgangMessages } from '../vorgang.messages';
import { AdditionalActions, VorgangWithEingangResource } from '../vorgang.model';
import {
  ApiRootWithLinkRelProps,
  HttpErrorAction,
  SearchVorgaengeByProps,
  VorgangUriWithContextProps,
  VorgangWithEingangAction,
} from './vorgang.actions';
import { VorgangFacade } from './vorgang.facade';
import { VorgangRepository } from './vorgang.repository';

import * as BinaryFileActions from '../../../../binary-file-shared/src/lib/+state/binary-file.actions';
import * as VorgangActions from './vorgang.actions';
import * as VorgangSelectors from './vorgang.selectors';

@Injectable()
export class VorgangEffects {
  constructor(
    private readonly actions$: Actions,
    private store: Store,
    private repository: VorgangRepository,
    private apiRootFacade: ApiRootFacade,
    private vorgangFacade: VorgangFacade,
    private snackbarService: SnackBarService,
  ) {}

  //VorgangList
  loadVorgangList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VorgangActions.loadVorgangList),
      switchMap((props: ApiRootWithLinkRelProps) =>
        this.repository.loadVorgangList(props.apiRoot, props.linkRel).pipe(
          map((loadedVorgangList) =>
            VorgangActions.loadVorgangListSuccess({ vorgangList: loadedVorgangList }),
          ),
          catchError((error) =>
            of(
              VorgangActions.loadVorgangListFailure({
                apiError: getApiErrorFromHttpErrorResponse(error),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  loadNextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VorgangActions.loadNextPage),
      concatLatestFrom(() => this.store.select(VorgangSelectors.vorgangList)),
      switchMap(([, vorgangList]) =>
        this.repository.getNextVorgangListPage(vorgangList.resource).pipe(
          map((loadedVorgangList) =>
            VorgangActions.loadNextPageSuccess({ vorgangList: loadedVorgangList }),
          ),
          catchError((error) =>
            of(
              VorgangActions.loadVorgangListFailure({
                apiError: getApiErrorFromHttpErrorResponse(error),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  searchVorgaengeBy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VorgangActions.searchVorgaengeBy),
      switchMap((props: SearchVorgaengeByProps) =>
        this.repository.searchVorgaengeBy(props.apiRoot, props.searchString, props.linkRel).pipe(
          map((loadedVorgangList) =>
            VorgangActions.searchVorgaengeBySuccess({ vorgangList: loadedVorgangList }),
          ),
          catchError((error) =>
            of(VorgangActions.searchVorgaengeByFailure({ httpErrorResponse: error })),
          ),
        ),
      ),
    ),
  );

  searchForPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VorgangActions.searchForPreview),
      concatLatestFrom(() => [
        this.apiRootFacade.getApiRoot(),
        this.vorgangFacade.getVorgangFilter(),
      ]),
      switchMap(([stringBasedProps, apiRoot, vorgangFilter]) =>
        this.repository
          .searchVorgaengeBy(
            apiRoot.resource,
            stringBasedProps.string,
            getSearchLinkRel(vorgangFilter),
            VorgangListService.SEARCH_PREVIEW_LIST_LIMIT,
          )
          .pipe(
            map((loadedVorgangList) =>
              VorgangActions.searchForPreviewSuccess({ vorgangList: loadedVorgangList }),
            ),
            catchError((error) =>
              of(VorgangActions.searchForPreviewFailure({ httpErrorResponse: error })),
            ),
          ),
      ),
    ),
  );

  showSearchError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VorgangActions.searchForPreviewFailure, VorgangActions.searchVorgaengeByFailure),
        map((action: HttpErrorAction) => this.showSearchError(action.httpErrorResponse)),
      ),
    { dispatch: false },
  );

  showSearchError(error: HttpErrorResponse): void {
    if (isServiceUnavailable(error.error.status)) {
      this.snackbarService.showError(VorgangMessages.SEARCH_UNAVAILABLE);
    }
  }

  //VorgangWithEingang
  loadVorgangWithEingang$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VorgangActions.loadVorgangWithEingang),
      switchMap((props: VorgangUriWithContextProps) =>
        this.repository.getVorgang(props.resourceUri).pipe(
          mergeMap((vorgangWithEingang) =>
            this.handleSuccessActions(vorgangWithEingang, props.additionalActions),
          ),
          catchError((error) => this.handleFailureActions(error, props.additionalActions)),
        ),
      ),
    ),
  );

  private handleSuccessActions(
    vorgangWithEingang: VorgangWithEingangResource,
    additionalActions: AdditionalActions,
  ): Action<string>[] {
    if (isNotUndefined(additionalActions?.additionalSuccessAction)) {
      return [
        VorgangActions.loadVorgangWithEingangSuccess({ vorgangWithEingang }),
        additionalActions.additionalSuccessAction(),
      ];
    }
    return [VorgangActions.loadVorgangWithEingangSuccess({ vorgangWithEingang })];
  }

  private handleFailureActions(
    error: HttpErrorResponse,
    additionalActions: AdditionalActions,
  ): Observable<Action<string>> {
    if (isNotUndefined(additionalActions?.additionalFailureAction)) {
      return of(
        VorgangActions.loadVorgangWithEingangFailure({
          apiError: getApiErrorFromHttpErrorResponse(error),
        }),
        additionalActions.additionalFailureAction(error),
      );
    }
    return of(
      VorgangActions.loadVorgangWithEingangFailure({
        apiError: getApiErrorFromHttpErrorResponse(error),
      }),
    );
  }

  loadVorgangWithEingangSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VorgangActions.loadVorgangWithEingangSuccess),
      filter((props: VorgangWithEingangAction) =>
        hasLink(props.vorgangWithEingang, VorgangWithEingangLinkRel.PENDING_COMMANDS),
      ),
      switchMap((props: VorgangWithEingangAction) =>
        of(VorgangActions.loadPendingCommandList({ vorgangWithEingang: props.vorgangWithEingang })),
      ),
    ),
  );

  loadPendingCommandList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VorgangActions.loadPendingCommandList),
      switchMap((props: VorgangWithEingangAction) =>
        of(loadCommandList(this.createLoadPendingCommandListProps(props.vorgangWithEingang))),
      ),
    ),
  );

  createLoadPendingCommandListProps(
    vorgangWithEingang: VorgangWithEingangResource,
  ): LoadCommandListProps {
    return {
      resource: vorgangWithEingang,
      linkRel: VorgangWithEingangLinkRel.PENDING_COMMANDS,
      successAction: (commandList: CommandListResource) =>
        VorgangActions.loadPendingCommandListSuccess({ commandList }),
      failureAction: (apiError: ApiError) =>
        VorgangActions.loadPendingCommandListFailure({ apiError }),
    };
  }

  loadRepresentationList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VorgangActions.loadRepresentationList),
      switchMap((props: VorgangWithEingangAction) =>
        of(loadBinaryFileList(this.createLoadRepresentationListProps(props.vorgangWithEingang))),
      ),
    ),
  );

  createLoadRepresentationListProps(
    vorgangWithEingang: VorgangWithEingangResource,
  ): LoadBinaryFileListProps {
    return {
      resource: vorgangWithEingang,
      linkRel: VorgangWithEingangLinkRel.REPRESENTATIONS,
      successAction: (binaryFileList: BinaryFileListResource) =>
        VorgangActions.loadRepresentationListSuccess({ binaryFileList }),
      failureAction: (apiError: ApiError) =>
        VorgangActions.loadRepresentationListFailure({ apiError }),
    };
  }

  loadAttachmentList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VorgangActions.loadAttachmentList),
      switchMap((props: VorgangWithEingangAction) =>
        of(loadBinaryFileList(this.createLoadAttachmentListProps(props.vorgangWithEingang))),
      ),
    ),
  );

  createLoadAttachmentListProps(
    vorgangWithEingang: VorgangWithEingangResource,
  ): LoadBinaryFileListProps {
    return {
      resource: vorgangWithEingang,
      linkRel: VorgangWithEingangLinkRel.ATTACHMENTS,
      successAction: (binaryFileList: BinaryFileListResource) =>
        VorgangActions.loadAttachmentListSuccess({ binaryFileList }),
      failureAction: (apiError: ApiError) => VorgangActions.loadAttachmentListFailure({ apiError }),
    };
  }

  exportVorgang$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VorgangActions.exportVorgang),
      switchMap((props: VorgangWithEingangAction) =>
        this.repository.export(props.vorgangWithEingang).pipe(
          mergeMap((blobWithFileName: BlobWithFileName) => [
            BinaryFileActions.saveFile({
              fileData: blobWithFileName.blob,
              fileName: blobWithFileName.fileName,
            }),
            VorgangActions.exportVorgangSuccess(),
          ]),
          catchError((error) =>
            of(
              VorgangActions.exportVorgangFailure({
                apiError: getApiErrorFromHttpErrorResponse(error),
              }),
            ),
          ),
        ),
      ),
    ),
  );
}
