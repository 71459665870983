import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import * as UserProfileActions from './user-profile.actions';

export const USER_PROFILE_FEATURE_KEY = 'UserProfileState';

export interface UserProfilePartialState {
  readonly [USER_PROFILE_FEATURE_KEY]: UserProfileState;
}

export interface UserProfileState {
  userProfileSearchVisibilty: boolean;
}

export const initialState: UserProfileState = {
  userProfileSearchVisibilty: false,
};

const userProfileReducer: ActionReducer<UserProfileState, Action> = createReducer(
  initialState,
  on(
    UserProfileActions.showUserProfileSearch,
    (state: UserProfileState): UserProfileState => ({
      ...state,
      userProfileSearchVisibilty: true,
    }),
  ),
  on(
    UserProfileActions.hideUserProfileSearch,
    (state: UserProfileState): UserProfileState => ({
      ...state,
      userProfileSearchVisibilty: false,
    }),
  ),
);

export function reducer(state: UserProfileState, action: Action): UserProfileState {
  return userProfileReducer(state, action);
}
