import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApiRootModule } from '@alfa-client/api-root-shared';
import { TechSharedModule } from '@alfa-client/tech-shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HintEffects } from './+state/hint.effects';
import { HintFacade } from './+state/hint.facade';
import { HINT_FEATURE_KEY, reducer } from './+state/hint.reducer';
import { HintRepository } from './+state/hint.repository';
import { HintService } from './hint.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(HINT_FEATURE_KEY, reducer),
    EffectsModule.forFeature([HintEffects]),
    TechSharedModule,
    ApiRootModule,
  ],
  providers: [HintService, HintFacade, HintRepository],
})
export class HintSharedModule {}
