/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpErrorHandler {
  private readonly defaultHandling: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly retryOnConnectionTimeout: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  private postErrorActionByName: { [key: string]: any } = {};

  public registerCustomHandling(name: string, doAfterErrorReceived: any): void {
    if (isNil(this.postErrorActionByName[name])) {
      this.postErrorActionByName[name] = doAfterErrorReceived;
    } else {
      console.error(`HttpErrorHandler: duplicate post error action registration of ${name}`);
    }
  }

  public doAfterErrorReceived(name: string, errorResponse: HttpErrorResponse): void {
    if (!isNil(this.postErrorActionByName[name])) {
      this.postErrorActionByName[name](errorResponse);
    } else {
      console.error(`HttpErrorHandler: no post error action defined for ${name}`);
    }
  }

  public enableDefaultHandling(): void {
    this.defaultHandling.next(true);
  }

  public shouldDoDefaultHandling(): boolean {
    return this.defaultHandling.value;
  }

  public disableDefaultHandling(): void {
    this.defaultHandling.next(false);
  }

  public enableRetry(): void {
    this.retryOnConnectionTimeout.next(true);
  }

  public shouldDoRetry(): boolean {
    return this.retryOnConnectionTimeout.value;
  }

  public disableRetry(): void {
    this.retryOnConnectionTimeout.next(false);
  }
}
