<!--

    Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<ng-container *ngIf="userSettings.resource | hasLink: userSettingsLinkRel.EDIT">
  <h2 class="mb-2 text-base font-bold text-text">E-Mail Benachrichtigungen</h2>
  <ozgcloud-slide-toggle
    [checked]="
      isChecked(UserNotifications.VORGANGE_CREATED) ||
      isChecked(UserNotifications.NOTIFICATIONS_SEND_FOR)
    "
    (valueChanged)="emitSettingsForVorgangCreated($event)"
    data-test-id="email-benachrichtigung-neuer-Vorgang"
    label="Neuer Vorgang im Alfa"
    toolTip="E-Mail-Benachrichtigung bei Eingang eines Antrags"
  >
  </ozgcloud-slide-toggle>
  <ozgcloud-slide-toggle
    [checked]="isChecked(UserNotifications.POSTFACH_NACHRICHT_FROM_ANTRAGSTELLER)"
    (valueChanged)="emitSettings(UserNotifications.POSTFACH_NACHRICHT_FROM_ANTRAGSTELLER, $event)"
    data-test-id="email-benachrichtigung-neue-Nachricht-Antragsteller"
    label="Neue Nachricht vom Antragsteller"
    toolTip="E-Mail-Benachrichtigung bei neu eingegangener Nachricht des Antragstellers bei einem mir zugewiesenen Vorgang"
    *ngIf="resourceHasKey(UserNotifications.POSTFACH_NACHRICHT_FROM_ANTRAGSTELLER)"
  >
  </ozgcloud-slide-toggle>
  <ozgcloud-slide-toggle
    [checked]="isChecked(UserNotifications.VORGANG_ASSIGNED_TO_USER)"
    (valueChanged)="emitSettings(UserNotifications.VORGANG_ASSIGNED_TO_USER, $event)"
    data-test-id="email-benachrichtigung-neuer-Vorgang-Assigned"
    label="Vorgang wurde mir zugewiesen"
    toolTip="E-Mail-Benachrichtigung bei fälliger Wiedervorlage eines mir zugewiesenen Vorgangs"
    *ngIf="resourceHasKey(UserNotifications.VORGANG_ASSIGNED_TO_USER)"
  >
  </ozgcloud-slide-toggle>
  <ozgcloud-slide-toggle
    [checked]="isChecked(UserNotifications.WIEDERVORLAGE_DUE_TODAY)"
    (valueChanged)="emitSettings(UserNotifications.WIEDERVORLAGE_DUE_TODAY, $event)"
    data-test-id="email-benachrichtigung-Faellige-Wiedervorlage"
    label="Fällige Wiedervorlage"
    toolTip="	E-Mail-Benachrichtigung bei Zuweisung eines Vorgangs an mich"
    *ngIf="resourceHasKey(UserNotifications.WIEDERVORLAGE_DUE_TODAY)"
  >
  </ozgcloud-slide-toggle>
</ng-container>
