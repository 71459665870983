import { ApiErrorAction, TypedActionCreatorWithProps } from '@alfa-client/tech-shared';
import { createAction, props } from '@ngrx/store';
import { HintListResource } from '../hint.model';

export interface HintListProps {
  hintList: HintListResource;
}

export const loadHintListSuccess: TypedActionCreatorWithProps<HintListProps> = createAction(
  '[Hint] Load All Success',
  props<HintListProps>(),
);
export const loadHintListFailed: TypedActionCreatorWithProps<ApiErrorAction> = createAction(
  '[Hint] Load All Failed',
  props<ApiErrorAction>(),
);
