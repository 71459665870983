/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Resource } from '@ngxp/rest';

export enum UserNotificationsTypes {
  NOTIFICATIONS_SEND_FOR = 'notificationsSendFor',
  VORGANGE_CREATED = 'vorgangCreated',
  VORGANG_ASSIGNED_TO_USER = 'vorgangAssignedToUser',
  POSTFACH_NACHRICHT_FROM_ANTRAGSTELLER = 'postfachNachrichtFromAntragsteller',
  WIEDERVORLAGE_DUE_TODAY = 'wiedervorlageDueToday',
}

export enum NotificationSendForTypes {
  ALL = 'ALL',
  NONE = 'NONE',
}

export type UserSettings = Partial<{
  [UserNotificationsTypes.NOTIFICATIONS_SEND_FOR]: NotificationSendForTypes;
  [UserNotificationsTypes.VORGANGE_CREATED]: boolean;
  [UserNotificationsTypes.VORGANG_ASSIGNED_TO_USER]: boolean;
  [UserNotificationsTypes.POSTFACH_NACHRICHT_FROM_ANTRAGSTELLER]: boolean;
  [UserNotificationsTypes.WIEDERVORLAGE_DUE_TODAY]: boolean;
}>;

export interface UserSettingsResource extends UserSettings, Resource {}
