import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';

@Component({
  selector: 'ods-public-administration-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './public-administration-icon.component.html',
})
export class PublicAdministrationIconComponent {
  @Input() class: string = undefined;

  readonly twMerge = twMerge;
}
