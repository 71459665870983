/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
export enum VorgangStatusLabel {
  NEU = 'Neu',
  ANGENOMMEN = 'Angenommen',
  VERWORFEN = 'Verworfen',
  IN_BEARBEITUNG = 'In Bearbeitung',
  BESCHIEDEN = 'Beschieden',
  ZURUECKGESTELLT = 'Zurückgestellt',
  ABGESCHLOSSEN = 'Abgeschlossen',
  WEITERGELEITET = 'Weitergeleitet',
  ZU_LOESCHEN = 'Zu Löschen',
}

export enum VorgangMessages {
  SEARCH_UNAVAILABLE = 'Die Suche ist vorübergehen nicht verfügbar. Versuchen Sie es zu einem späteren Zeitpunkt erneut.',
}
