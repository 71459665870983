/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { hasContent } from '@alfa-client/tech-shared';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SubscriptSizing } from '@angular/material/form-field';
import { FormControlEditorAbstractComponent } from 'libs/design-component/src/lib/form/formcontrol-editor.abstract.component';
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'ozgcloud-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent
  extends FormControlEditorAbstractComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('inputElement') inputElement: ElementRef;

  @Input() label: string;
  @Input() placeholder: string;
  @Input() placeholderLabel: string = null;
  @Input() autocomplete: 'off' | 'email' = 'off';
  @Input() appearance: 'outline' | 'fill' = 'fill';
  @Input() readOnly: boolean;
  @Input() autoFocus: boolean;
  @Input() clearable: boolean = false;
  @Input() required: boolean = false;
  @Input() subscriptSizing: SubscriptSizing = 'fixed';

  showClearButton$: Observable<boolean>;

  ngOnInit() {
    super.ngOnInit();
    this.showClearButton$ = this.fieldControl.valueChanges
      .pipe(startWith(this.fieldControl.value))
      .pipe(map((value) => hasContent(value) && this.clearable));
  }

  ngAfterViewInit(): void {
    window.setTimeout(() => {
      if (this.autoFocus) this.inputElement.nativeElement.focus();
    });
  }

  getPlaceholderLabel(): string {
    return this.placeholderLabel ? this.placeholderLabel : this.label;
  }

  onClear(): void {
    this.writeValue(null);
  }
}
