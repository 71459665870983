import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_CONFIG, Environment } from '@alfa-client/environment-shared';
import { OAuthService } from 'angular-oauth2-oidc';
import { removeIdTokenFromSessionStorage } from './storage/storage';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private oAuthService: OAuthService,
    @Inject(ENVIRONMENT_CONFIG) private envConfig: Environment,
  ) {}

  public hasNoValidToken(): boolean {
    return !(this.oAuthService.hasValidAccessToken() && this.oAuthService.hasValidIdToken());
  }

  public logout(): void {
    this.oAuthService.logOut();
  }

  public logoutWithConfirmation(): void {
    removeIdTokenFromSessionStorage();

    const customParameters: object = { client_id: this.envConfig.clientId };
    this.oAuthService.logOut(customParameters);
  }
}
