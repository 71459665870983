import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ods-attachment-header',
  standalone: true,
  imports: [CommonModule],
  template: `<div class="flex h-11 items-center justify-between px-3 empty:hidden">
    <h4 class="text-sm font-medium text-text" *ngIf="title">{{ title }}</h4>
    <ng-content select="[action-buttons]"></ng-content>
  </div>`,
})
export class AttachmentHeaderComponent {
  @Input() title: string = '';
}
