/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { AbstractFormService, ProblemDetail, StateResource } from '@alfa-client/tech-shared';
import { UserProfileListResource, UserProfileService } from '@alfa-client/user-profile-shared';
import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { isNil } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { ValidationMessageCode } from '../../../../../tech-shared/src/lib/validation/tech.validation.messages';

@Injectable()
export class UserProfileSearchFormService extends AbstractFormService implements OnDestroy {
  public static readonly SEARCH_FIELD = 'searchBy';

  private FIELD_PATH: string = 'only.fe';

  private valueSubscription: Subscription;

  constructor(
    formBuilder: UntypedFormBuilder,
    private userProfileService: UserProfileService,
  ) {
    super(formBuilder);
  }

  protected initForm(): UntypedFormGroup {
    return this.formBuilder.group({
      [UserProfileSearchFormService.SEARCH_FIELD]: new UntypedFormControl(),
    });
  }

  protected doSubmit(): Observable<StateResource<UserProfileListResource>> {
    return this.userProfileService.search(this.getSearchString());
  }

  private getSearchString(): string {
    return this.getFormValue()[UserProfileSearchFormService.SEARCH_FIELD];
  }

  protected getPathPrefix(): string {
    return this.FIELD_PATH;
  }

  public setEmptyUserProfileError(): void {
    this.setErrorByProblemDetail(emptyUserProfileError);
  }

  public setNoUserProfileFoundError(): void {
    this.setErrorByProblemDetail(noUserProfileFoundError);
  }

  ngOnDestroy(): void {
    if (!isNil(this.valueSubscription)) this.valueSubscription.unsubscribe();
  }
}

const noUserProfileFoundError: ProblemDetail = {
  type: null,
  title: null,
  status: null,
  detail: null,
  instance: null,
  invalidParams: [
    {
      name: 'only.fe.searchBy',
      reason: ValidationMessageCode.FIELD_ASSIGN_BEARBEITER_NOT_EXIST,
      constraintParameters: [],
      value: null,
    },
  ],
};

const emptyUserProfileError: ProblemDetail = {
  type: null,
  title: null,
  status: null,
  detail: null,
  instance: null,
  invalidParams: [
    {
      name: 'only.fe.searchBy',
      reason: ValidationMessageCode.FIELD_EMPTY,
      constraintParameters: [],
      value: null,
    },
  ],
};
