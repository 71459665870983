<div
  [ngClass]="{ 'text-error': isOverdue, 'text-text': !isOverdue }"
  data-test-class="wiedervorlage-icon"
>
  <ng-container *ngIf="isOverdue; else defaultFrist">
    <ozgcloud-svgicon
      svgIcon="resubmission_expired"
      data-test-class="wiedervorlage-icon-is-overdue"
    ></ozgcloud-svgicon>
  </ng-container>
  <ng-template #defaultFrist>
    <ozgcloud-svgicon
      svgIcon="update"
      data-test-class="wiedervorlage-icon-default"
    ></ozgcloud-svgicon>
  </ng-template>
</div>
