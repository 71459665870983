/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, EventEmitter, Output } from '@angular/core';
import { CommandResource, isDone } from '@alfa-client/command-shared';
import {
  StateResource,
  createEmptyStateResource,
  createStateResource,
} from '@alfa-client/tech-shared';
import { Selectable } from '@alfa-client/ui';
import {
  UserProfileListLinkRel,
  UserProfileListResource,
  UserProfileResource,
  UserProfileService,
} from '@alfa-client/user-profile-shared';
import { VorgangService } from '@alfa-client/vorgang-shared';
import { getEmbeddedResource, getUrl } from '@ngxp/rest';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'alfa-user-profile-search-container',
  templateUrl: './user-profile-search-container.component.html',
  styleUrls: ['./user-profile-search-container.component.scss'],
})
export class UserProfileSearchContainerComponent {
  @Output() assigned: EventEmitter<void> = new EventEmitter();

  readonly EMPTY_LIST = [];

  assignTo$: Observable<StateResource<CommandResource>> = of(
    createEmptyStateResource<CommandResource>(),
  );
  searchedUserProfiles$: Observable<StateResource<Selectable[]>> = of(
    createStateResource<Selectable[]>([]),
  );

  assignedToName: string = '';

  constructor(
    private vorgangService: VorgangService,
    private service: UserProfileService,
  ) {
    //TODO Das Parsen im Service machen bzw. mit NGRX ueber einen Selector
    this.searchedUserProfiles$ = this.getSearchUserProfiles();
  }

  private getSearchUserProfiles(): Observable<StateResource<Selectable[]>> {
    return this.service.getSearchedUserProfiles().pipe(
      map((userProfileListStateResource) => {
        if (userProfileListStateResource.loaded) {
          return createStateResource(
            this.parseToSelectables(userProfileListStateResource.resource),
          );
        }
        return { ...userProfileListStateResource, resource: this.EMPTY_LIST };
      }),
    );
  }

  parseToSelectables(userProfileListResource: UserProfileListResource): Selectable[] {
    const resources: UserProfileResource[] = getEmbeddedResource(
      userProfileListResource,
      UserProfileListLinkRel.USER_PROFILE_LIST,
    );
    return resources ? resources.map((userProfile) => this.parseToSelectable(userProfile)) : [];
  }

  parseToSelectable(userProfile: UserProfileResource) {
    return <Selectable>{
      label: userProfile.firstName + ' ' + userProfile.lastName,
      value: getUrl(userProfile),
    };
  }

  public assign(userProfile: Selectable): void {
    this.assignedToName = userProfile.label;
    this.assignTo$ = this.vorgangService.assignUser(userProfile.value).pipe(
      tap((commandStateResource: StateResource<CommandResource>) => {
        if (isDone(commandStateResource.resource)) {
          this.assigned.emit();
        }
      }),
    );
  }
}
