<!--

    Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<ozgcloud-icon-button-with-spinner
  [icon]="selectedItem.icon"
  [svgIcon]="selectedItem.svgIcon"
  [matMenuTriggerFor]="menu.matMenu"
  toolTip="Antworten erlauben/unterbinden"
  class="reply-toggle"
>
</ozgcloud-icon-button-with-spinner>

<ozgcloud-menu #menu class="reply-option-menu">
  <button
    *ngFor="let item of itemList"
    mat-menu-item
    (click)="setSelectedEnumItem(item)"
    [attr.data-test-id]="(item.type | convertForDataTest) + '-enum-item'"
    [attr.aria-label]="item.text"
  >
    <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
    <mat-icon *ngIf="!item.svgIcon">{{ item.icon }}</mat-icon>
    <span> {{ item.text }}</span>
  </button>
</ozgcloud-menu>

<mat-label>{{ label }}</mat-label>
<input
  [formControl]="fieldControl"
  [attr.data-test-id]="(label | convertForDataTest) + '-enum-input'"
  type="hidden"
  (blur)="touch()"
/>
