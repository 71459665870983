import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { createAriaLabelForIconButton, StateResource } from '@alfa-client/tech-shared';
import { Resource } from '@ngxp/rest';

@Component({
  selector: 'ozgcloud-icon-button-primary',
  templateUrl: './ozgcloud-icon-button-primary.component.html',
  styleUrls: ['./ozgcloud-icon-button-primary.component.scss'],
})
export class OzgcloudIconButtonPrimaryComponent implements OnInit {
  @Input() svgIcon: string;
  @Input() stateResource: StateResource<Resource>;
  @Input() tooltip = '';
  @Output() public clickEmitter: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  ariaLabel = '';

  ngOnInit(): void {
    this.ariaLabel = createAriaLabelForIconButton(this.tooltip, this.svgIcon);
  }
}
