import { isNotUndefined } from '@alfa-client/tech-shared';
import { getEmbeddedResource } from '@ngxp/rest';
import { HintListLinkRel } from './hint.linkrel';
import { HintMessageCode } from './hint.message';
import { HintListResource, HintResource } from './hint.model';

export function isUserHasNoOrganisationsEinheitIdHint(hint: HintResource): boolean {
  return hint.code === HintMessageCode.USER_NO_ORGANISATIONS_EINHEIT_ID;
}

export function hasUserNoOrganisationsEinheitIdHint(hintList: HintListResource): boolean {
  const hints: HintResource[] = getEmbeddedResource(hintList, HintListLinkRel.HINT_LIST);
  const hint = hints.find((hint) => isUserHasNoOrganisationsEinheitIdHint(hint));
  return isNotUndefined(hint);
}
