/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { createEmptyStateResource, StateResource } from '@alfa-client/tech-shared';
import {
  mapBooleanToNotificationSendFor,
  mapNotificationSendForToBoolean,
  mapUserSettingsResourceToUserSettings,
  UserNotificationsTypes,
  UserSettings,
  UserSettingsLinkRel,
  UserSettingsResource,
} from '@alfa-client/user-settings-shared';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'alfa-user-settings-email-benachrichtigung',
  templateUrl: './user-settings-email-benachrichtigung.component.html',
  styleUrls: ['./user-settings-email-benachrichtigung.component.scss'],
})
export class UserSettingsEmailBenachrichtigungComponent {
  @Input() userSettings: StateResource<UserSettingsResource> =
    createEmptyStateResource<UserSettingsResource>();

  @Output() valueChanged: EventEmitter<UserSettings> = new EventEmitter();

  readonly UserNotifications = UserNotificationsTypes;
  readonly userSettingsLinkRel = UserSettingsLinkRel;

  public isChecked(notificationType: UserNotificationsTypes): boolean {
    if (!this.resourceHasKey(notificationType)) {
      return false;
    }

    if (notificationType === UserNotificationsTypes.NOTIFICATIONS_SEND_FOR) {
      return mapNotificationSendForToBoolean(this.userSettings.resource[notificationType]);
    }

    return this.userSettings.resource[notificationType];
  }

  public resourceHasKey(notificationType: UserNotificationsTypes): boolean {
    return this.userSettings.resource[notificationType] !== undefined;
  }

  public emitSettingsForVorgangCreated(value: boolean): void {
    this.valueChanged.emit({
      ...mapUserSettingsResourceToUserSettings(this.userSettings.resource),
      [UserNotificationsTypes.VORGANGE_CREATED]: value,
      [UserNotificationsTypes.NOTIFICATIONS_SEND_FOR]: mapBooleanToNotificationSendFor(value),
    });
  }

  public emitSettings(type: UserNotificationsTypes, value: boolean): void {
    this.valueChanged.emit({
      ...mapUserSettingsResourceToUserSettings(this.userSettings.resource),
      [type]: value,
    });
  }
}
