import { EMPTY_STRING, isNotNil } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Resource } from '@ngxp/rest';
import { isEqual, isUndefined } from 'lodash-es';
import { Subscription, debounceTime, filter } from 'rxjs';
import { AriaLiveRegionComponent } from '../../aria-live-region/aria-live-region.component';
import { SearchFieldComponent } from '../search-field/search-field.component';
import { SearchResultHeaderComponent } from '../search-result-header/search-result-header.component';
import { SearchResultItemComponent } from '../search-result-item/search-result-item.component';
import { SearchResultLayerComponent } from '../search-result-layer/search-result-layer.component';
import { InstantSearchQuery, InstantSearchResult } from './instant-search.model';

@Component({
  selector: 'ods-instant-search',
  standalone: true,
  imports: [
    CommonModule,
    SearchFieldComponent,
    SearchResultHeaderComponent,
    SearchResultItemComponent,
    SearchResultLayerComponent,
    AriaLiveRegionComponent,
  ],
  template: ` <div class="relative">
    <ods-search-field
      [placeholder]="placeholder"
      [attr.aria-expanded]="results.length"
      [control]="control"
      aria-controls="results"
      (inputClicked)="showResults()"
      (searchQueryCleared)="searchQueryCleared.emit()"
      #searchField
    />
    <ods-aria-live-region [text]="ariaLiveText" />
    <ods-search-result-layer
      *ngIf="results.length && areResultsVisible"
      containerClass="absolute z-50 mt-3 max-h-[calc(50vh)] w-full overflow-y-auto"
      id="results"
    >
      <ods-search-result-header *ngIf="headerText" [text]="headerText" [count]="results.length" header />
      <ods-search-result-item
        *ngFor="let result of results; let i = index"
        [title]="result.title"
        [description]="result.description"
        (itemClicked)="onItemClicked(result, i)"
        #results
      ></ods-search-result-item>
    </ods-search-result-layer>
  </div>`,
})
export class InstantSearchComponent implements OnInit, OnDestroy {
  static readonly DEBOUNCE_TIME_IN_MILLIS: number = 300;

  @Input() placeholder: string = EMPTY_STRING;
  @Input() headerText: string = EMPTY_STRING;
  @Input() control: FormControl<string> = new FormControl(EMPTY_STRING);

  @Input() set searchResults(searchResults: InstantSearchResult<Resource>[]) {
    if (!isEqual(searchResults, this.results) && isNotNil(searchResults)) {
      this.setSearchResults(searchResults);
    }
  }

  @Output() searchClosed: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() searchResultSelected: EventEmitter<InstantSearchResult<Resource>> = new EventEmitter<InstantSearchResult<Resource>>();
  @Output() searchQueryChanged: EventEmitter<InstantSearchQuery> = new EventEmitter<InstantSearchQuery>();
  @Output() searchQueryCleared: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  readonly FIRST_ITEM_INDEX: number = 0;
  readonly PREVIEW_SEARCH_STRING_MIN_LENGTH: number = 2;
  results: InstantSearchResult<Resource>[] = [];
  ariaLiveText: string = '';
  areResultsVisible: boolean = true;
  private focusedResult: number | undefined = undefined;
  formControlSubscription: Subscription;

  constructor(public ref: ElementRef) {}

  @ViewChildren('results') resultsRef: QueryList<SearchResultItemComponent>;

  ngOnInit(): void {
    this.handleValueChanges();
  }

  handleValueChanges() {
    this.formControlSubscription = this.control.valueChanges
      .pipe(
        debounceTime(InstantSearchComponent.DEBOUNCE_TIME_IN_MILLIS),
        filter((value) => Boolean(value)),
        filter((value: string) => value.length >= this.PREVIEW_SEARCH_STRING_MIN_LENGTH),
      )
      .subscribe((searchBy: string) => {
        this.searchQueryChanged.emit({ searchBy });
        if (!this.areResultsVisible) {
          this.showResults();
        }
      });
  }

  ngOnDestroy(): void {
    if (isNotNil(this.formControlSubscription)) this.formControlSubscription.unsubscribe();
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(e: KeyboardEvent): void {
    if (this.isSearchResultsEmpty()) return;
    if (this.isArrowNavigationKey(e)) this.handleArrowNavigation(e);
    if (this.isEscapeKey(e)) this.handleEscape(e);
  }

  @HostListener('document:click', ['$event'])
  onClickHandler(e: MouseEvent): void {
    if (!this.ref.nativeElement.contains(e.target)) {
      this.hideResults();
    }
  }

  handleArrowNavigation(e: KeyboardEvent): void {
    e.preventDefault();
    const newIndex = this.getResultIndexForKey(e.key);
    this.focusedResult = newIndex;
    this.setFocusOnResultItem(newIndex);
  }

  handleEscape(e: KeyboardEvent): void {
    e.preventDefault();
    this.hideResults();
    this.searchClosed.emit();
  }

  setFocusOnResultItem(index: number): void {
    this.resultsRef.get(index).setFocus();
  }

  setSearchResults(searchResults: InstantSearchResult<Resource>[]): void {
    this.results = searchResults;
    this.ariaLiveText = this.buildAriaLiveText(searchResults.length);
  }

  getNextResultIndex(index: number | undefined, resultLength: number): number {
    if (isUndefined(index)) return this.FIRST_ITEM_INDEX;
    if (this.isLastItemOrOutOfArray(index, resultLength)) return this.FIRST_ITEM_INDEX;
    return index + 1;
  }

  getPreviousResultIndex(index: number | undefined, resultLength: number): number {
    if (isUndefined(index)) return this.getLastItemIndex(resultLength);
    if (this.isFirstItemOrOutOfArray(index)) return this.getLastItemIndex(resultLength);
    return index - 1;
  }

  getLastItemIndex(arrayLength: number): number {
    if (arrayLength < 1) return this.FIRST_ITEM_INDEX;
    return arrayLength - 1;
  }

  getResultIndexForKey(key: string): number {
    switch (key) {
      case 'ArrowDown':
        return this.getNextResultIndex(this.focusedResult, this.results.length);
      case 'ArrowUp':
        return this.getPreviousResultIndex(this.focusedResult, this.results.length);
      default:
        console.error('Key %s not allowed', key);
    }
  }

  buildAriaLiveText(resultsLength: number): string {
    if (resultsLength === 1)
      return `Ein Suchergebnis für Eingabe ${this.control.value}. Nutze Pfeiltaste nach unten, um das zu erreichen.`;
    if (resultsLength > 1)
      return `${resultsLength} Suchergebnisse für Eingabe ${this.control.value}. Nutze Pfeiltaste nach unten, um diese zu erreichen.`;
    return 'Keine Ergebnisse';
  }

  showResults(): void {
    this.areResultsVisible = true;
    this.focusedResult = undefined;
  }

  hideResults(): void {
    this.areResultsVisible = false;
    this.focusedResult = undefined;
  }

  isLastItemOrOutOfArray(index: number, arrayLength: number): boolean {
    return index >= arrayLength - 1;
  }

  isFirstItemOrOutOfArray(index: number): boolean {
    return index <= this.FIRST_ITEM_INDEX;
  }

  isSearchResultsEmpty(): boolean {
    return this.results.length === 0;
  }

  isArrowNavigationKey(e: KeyboardEvent): boolean {
    return e.key === 'ArrowDown' || e.key === 'ArrowUp';
  }

  isEscapeKey(e: KeyboardEvent): boolean {
    return e.key === 'Escape';
  }

  onItemClicked(searchResult: InstantSearchResult<Resource>, index: number): void {
    this.searchResultSelected.emit(searchResult);
    this.focusedResult = index;
    this.hideResults();
  }
}
