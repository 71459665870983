import { TechSharedModule } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'ods-file-upload-button',
  standalone: true,
  imports: [CommonModule, TechSharedModule],
  styles: [':host {@apply inline-flex}'],
  templateUrl: './file-upload-button.component.html',
})
export class FileUploadButtonComponent {
  @Input({ required: true }) id!: string;
  @Input() isLoading: boolean = false;
  @Input() accept: string = '*/*';

  @ViewChild('inputElement') inputElement: ElementRef = new ElementRef({});

  resetInput(): void {
    this.inputElement.nativeElement.value = '';
  }
}
