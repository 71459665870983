/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, Input, SimpleChanges } from '@angular/core';
import { StateResource, createEmptyStateResource } from '@alfa-client/tech-shared';
import { UserProfileResource, UserProfileService } from '@alfa-client/user-profile-shared';
import { VorgangHeaderLinkRel, VorgangWithEingangResource } from '@alfa-client/vorgang-shared';
import { hasLink } from '@ngxp/rest';
import { isNil } from 'lodash-es';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'alfa-user-profile-in-vorgang-container',
  templateUrl: './user-profile-in-vorgang-container.component.html',
  styleUrls: ['./user-profile-in-vorgang-container.component.scss'],
})
export class UserProfileInVorgangContainerComponent {
  @Input() vorgang: VorgangWithEingangResource;

  userProfile$: Observable<StateResource<UserProfileResource>> = of(
    createEmptyStateResource<UserProfileResource>(),
  );

  constructor(public userProfileService: UserProfileService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.vorgang &&
      !isNil(this.vorgang) &&
      hasLink(this.vorgang, VorgangHeaderLinkRel.ASSIGNED_TO)
    ) {
      this.userProfile$ = this.userProfileService.getAssignedUserProfile(
        this.vorgang,
        VorgangHeaderLinkRel.ASSIGNED_TO,
      );
    }
  }
}
