/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { EMPTY_STRING } from '@alfa-client/tech-shared';
import { Vorgang, VorgangWithEingangResource } from '@alfa-client/vorgang-shared';
import { NavigationEnd } from '@angular/router';

export const VORGANG_KEIN_AKTENZEICHEN_ZUGEWIESEN = 'kein Aktenzeichen';

export function getAktenzeichenText(vorgang: Vorgang): string {
  return vorgang.aktenzeichen || VORGANG_KEIN_AKTENZEICHEN_ZUGEWIESEN;
}

export function getEmpfaenger(vorgangWithEingangResource: VorgangWithEingangResource): string {
  return `${getVorname(vorgangWithEingangResource)} ${getNachname(vorgangWithEingangResource)}`.trim();
}

export function isVorgangSearchRoute(navigationEnd: NavigationEnd): boolean {
  return navigationEnd.url.endsWith('/search');
}

function getVorname(vorgangWithEingangResource: VorgangWithEingangResource): string {
  return vorgangWithEingangResource.eingang.antragsteller?.vorname ?? EMPTY_STRING;
}

function getNachname(vorgangWithEingangResource: VorgangWithEingangResource): string {
  return vorgangWithEingangResource.eingang.antragsteller?.nachname ?? EMPTY_STRING;
}
