/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CheckboxEnumEditorItem } from '@alfa-client/ui';
import { getEmbeddedResource } from '@ngxp/rest';
import { isNil } from 'lodash-es';
import { PostfachMailListLinkRel } from './postfach.linkrel';
import { postfachNachrichtMessageCodeMessages } from './postfach.message-code';
import {
  CreatePostfachMailCommand,
  Direction,
  PostfachMail,
  PostfachMailListResource,
  PostfachMailResource,
  PostfachNachrichtenCount,
  PostfachOrder,
  ReplyOption,
} from './postfach.model';

export function createSendPostfachMailCommand(
  postfachMail: PostfachMail,
): CreatePostfachMailCommand {
  return { order: PostfachOrder.SEND_POSTFACH_NACHRICHT, body: postfachMail };
}

export function createResendPostfachMailCommand(): CreatePostfachMailCommand {
  return { order: PostfachOrder.RESEND_POSTFACH_MAIL, body: null };
}

export function isIncomingMail(postfachMail: PostfachMailResource): boolean {
  return postfachMail.direction == Direction.IN;
}

export function getMessageCodeMessage(postfachMail: PostfachMailResource): string {
  return postfachNachrichtMessageCodeMessages[postfachMail.messageCode];
}

export function getPostfachNachrichtenCount(
  postfachMailListResource: PostfachMailListResource,
): PostfachNachrichtenCount {
  if (isNil(postfachMailListResource)) {
    return PostfachNachrichtenCount.NONE;
  }

  const resources: PostfachMailResource[] = getEmbeddedResource<PostfachMailResource[]>(
    postfachMailListResource,
    PostfachMailListLinkRel.POSTFACH_MAIL_LIST,
  );
  if (isNil(resources) || resources.length == 0) {
    return PostfachNachrichtenCount.NONE;
  }
  if (resources.length == 1) {
    return PostfachNachrichtenCount.SINGLE;
  }
  if (resources.length > 1) {
    return PostfachNachrichtenCount.MULTIPLE;
  }
}

export const FORBIDDEN_REPLY_OPTION_ITEM: CheckboxEnumEditorItem = {
  type: ReplyOption.FORBIDDEN,
};
export const POSSIBLE_REPLY_OPTION_ITEM: CheckboxEnumEditorItem = {
  type: ReplyOption.POSSIBLE,
};
