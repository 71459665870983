/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  BinaryFileListResource,
  LoadBinaryFileListSuccessProps,
} from '@alfa-client/binary-file-shared';
import {
  ApiErrorAction,
  StateResource,
  createEmptyStateResource,
  createErrorStateResource,
  createStateResource,
} from '@alfa-client/tech-shared';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import * as PostfachActions from './postfach.actions';

export const POSTFACH_FEATURE_KEY = 'PostfachState';

export interface PostfachPartialState {
  readonly [POSTFACH_FEATURE_KEY]: PostfachState;
}
export interface PostfachState {
  isDownloadPdfInProgress: boolean;
  attachmentList: StateResource<BinaryFileListResource>;
}

export const initialPostfachState: PostfachState = {
  isDownloadPdfInProgress: false,
  attachmentList: createEmptyStateResource(),
};

const reducer: ActionReducer<PostfachState, Action> = createReducer(
  initialPostfachState,
  on(
    PostfachActions.startDownloadAsPdf,
    (state: PostfachState): PostfachState => ({
      ...state,
      isDownloadPdfInProgress: true,
    }),
  ),
  on(
    PostfachActions.downloadAsPdfSuccess,
    (state: PostfachState): PostfachState => ({
      ...state,
      isDownloadPdfInProgress: false,
    }),
  ),
  on(
    PostfachActions.downloadAsPdfFailed,
    (state: PostfachState): PostfachState => ({
      ...state,
      isDownloadPdfInProgress: false,
    }),
  ),

  on(
    PostfachActions.loadAttachmentList,
    (state: PostfachState): PostfachState => ({
      ...state,
      attachmentList: { ...state.attachmentList, loading: true },
    }),
  ),
  on(
    PostfachActions.loadAttachmentListSuccess,
    (state: PostfachState, props: LoadBinaryFileListSuccessProps): PostfachState => ({
      ...state,
      attachmentList: createStateResource(props.binaryFileList),
    }),
  ),
  on(
    PostfachActions.loadAttachmentListFailure,
    (state: PostfachState, props: ApiErrorAction): PostfachState => ({
      ...state,
      attachmentList: createErrorStateResource(props.apiError),
    }),
  ),
  on(
    PostfachActions.clearAttachmentList,
    (state: PostfachState): PostfachState => ({
      ...state,
      attachmentList: createEmptyStateResource(),
    }),
  ),
);

export function postfachReducer(state: PostfachState, action: Action): PostfachState {
  return reducer(state, action);
}
