/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Injectable } from '@angular/core';
import { StateResource } from '@alfa-client/tech-shared';
import { UserProfileResource } from '@alfa-client/user-profile-shared';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserSettings, UserSettingsResource } from '../user-settings.model';
import * as UserSettingsAction from './user-settings.actions';
import * as UserSettingsSelectors from './user-settings.selectors';

@Injectable()
export class UserSettingsFacade {
  constructor(private readonly store: Store) {}

  public loadUserSettings(currentUser: UserProfileResource): void {
    this.store.dispatch(UserSettingsAction.loadUserSettings({ currentUser }));
  }

  public getUserSettings(): Observable<StateResource<UserSettingsResource>> {
    return this.store.select(UserSettingsSelectors.userSettings);
  }

  public setUserSettings(userSettings: UserSettings): void {
    this.store.dispatch(UserSettingsAction.setUserSettings({ userSettings }));
  }
}
