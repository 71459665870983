import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { isNotEmpty } from '@alfa-client/tech-shared';
import { FileIconComponent } from '../icons/file-icon/file-icon.component';
import { SpinnerIconComponent } from '../icons/spinner-icon/spinner-icon.component';

@Component({
  selector: 'ods-attachment',
  standalone: true,
  imports: [CommonModule, SpinnerIconComponent, FileIconComponent],
  styles: [':host {@apply flex}'],
  template: `<button
    class="relative flex w-full items-start gap-3 border-b border-b-grayborder bg-background-100 px-3 py-2 hover:bg-background-200"
    [disabled]="isLoading"
    [attr.aria-disabled]="isLoading"
  >
    <div class="flex-shrink">
      <ods-file-icon
        *ngIf="fileType && !isLoading && !hasError"
        [fileType]="fileType"
        size="large"
      />
      <ods-file-icon *ngIf="!isLoading && hasError" fileType="exclamation" size="large" />
      <ods-spinner-icon *ngIf="isLoading && !hasError" size="large" />
    </div>
    <div class="flex grow flex-col items-start break-all text-start text-text">
      <p *ngIf="!hasError && !isLoading && caption" class="text-sm">
        {{ caption }}
      </p>
      <p *ngIf="hasError && errorCaption" class="text-sm text-error">
        {{ errorCaption }}
      </p>
      <p *ngIf="isLoading && loadingCaption" class="text-sm">
        {{ loadingCaption }}
      </p>
      <p *ngIf="description && !hasError" class="text-xs text-text/65">
        {{ description }}
      </p>
      <p *ngFor="let error of errors" class="text-xs text-text/65">
        {{ error }}
      </p>
    </div>
    <ng-content select="[close]" *ngIf="!isLoading"></ng-content>
  </button>`,
})
export class AttachmentComponent {
  @Input() caption: string = '';
  @Input() errorCaption: string = '';
  @Input() loadingCaption: string = '';
  @Input() fileType: string = '';
  @Input() description = '';
  @Input() isLoading: boolean = false;

  @Input() set errorMessages(errorMessages: string[]) {
    this.hasError = isNotEmpty(errorMessages);
    this.errors = errorMessages;
  }

  public errors: string[] = [];
  public hasError: boolean = false;
}
