<div
  *ngIf="showSpinner"
  data-test-class="spinner-transparency"
  class="spinner"
  [style.padding]="'16px'"
>
  <mat-spinner [diameter]="70"></mat-spinner>
</div>

<ng-content *ngIf="!showSpinner" data-test-class="spinner-transparency-content"></ng-content>
