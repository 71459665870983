import { StateResource, getEmbeddedResources } from '@alfa-client/tech-shared';
import { BinaryFileListLinkRel } from './binary-file.linkrel';
import { BinaryFileListResource, BinaryFileResource } from './binary-file.model';

export function getBinaryFiles(
  binaryFileListResource: StateResource<BinaryFileListResource>,
): BinaryFileResource[] {
  return getEmbeddedResources(binaryFileListResource, BinaryFileListLinkRel.FILE_LIST);
}

export enum BinaryFileIcon {
  'application/pdf' = 'pdf',
  'application/json' = 'json',
  'application/msword' = 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'doc',
  'application/xml' = 'xml',
  'text/xml' = 'xml',
  'image/apng' = 'image',
  'image/gif' = 'image',
  'image/jpeg' = 'image',
  'image/png' = 'image',
  'image/svg+xml' = 'image',
}
