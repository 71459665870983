import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fileSizePlain' })
export class FileSizePlainPipe implements PipeTransform {
  readonly kB = 1024;
  readonly MB = Math.pow(this.kB, 2);
  readonly GB = Math.pow(this.kB, 3);

  transform(size: number): string {
    if (size >= this.GB) return this.formatFileSize(size / this.GB, 'GB');
    if (size >= this.MB) return this.formatFileSize(size / this.MB, 'MB');
    return this.formatFileSize(size / this.kB, 'kB');
  }

  private formatFileSize(number: number, unit: string): string {
    return `${number.toFixed(2).replace('.', ',')} ${unit}`;
  }
}
