import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ods-search-result-header',
  standalone: true,
  imports: [CommonModule],
  template: `
    <h3 class="mx-6 my-3 w-fit border-b-2 border-primary py-1 text-sm font-semibold text-text">
      {{ text }} ({{ count }})
    </h3>
  `,
})
export class SearchResultHeaderComponent {
  @Input({ required: true }) text!: string;
  @Input() count: number = 0;
}
