import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'ods-radio-button-card',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './radio-button-card.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class RadioButtonCardComponent {
  @Input({ required: true }) label!: string;
  @Input({ required: true }) name!: string;
  @Input({ required: true }) value!: string;
  @Input() variant: string = '';
  @Input() fullWidthText: boolean = false;

  getClass(variant: string): string {
    switch (variant) {
      case 'bescheid_bewilligt':
        return 'peer-checked:border-bewilligt peer-checked:bg-bewilligt-100 border border-ozggray-700 bg-mainbg peer-checked:font-semibold peer-checked:shadow px-9 py-4';
      case 'bescheid_abgelehnt':
        return 'peer-checked:border-abgelehnt peer-checked:bg-abgelehnt-100 border border-ozggray-700 bg-mainbg peer-checked:font-semibold peer-checked:shadow px-9 py-4';
      case 'bescheid_save':
        return 'border-3 border-mainbg peer-checked:border-ozgblue-650 bg-background-50 hover:bg-background-100 px-6 py-2';
      default:
        return '';
    }
  }
}
