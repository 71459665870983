import { Component, Input } from '@angular/core';

@Component({
  selector: 'alfa-wiedervorlage-icon',
  templateUrl: './wiedervorlage-icon.component.html',
  styleUrls: ['./wiedervorlage-icon.component.scss'],
})
export class WiedervorlageIconComponent {
  @Input() isOverdue: boolean;
}
