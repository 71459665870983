import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { revertDoubleEncodingOfPercentageSign } from './navigation.util';

export class OzgCloudUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    return super.parse(url);
  }

  serialize(tree: UrlTree): string {
    const path: string = super.serialize(tree);
    return revertDoubleEncodingOfPercentageSign(path);
  }
}
