import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';

@Component({
  selector: 'ods-external-unit-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './external-unit-icon.component.html',
})
export class ExternalUnitIconComponent {
  @Input() class: string = undefined;

  readonly twMerge = twMerge;
}
