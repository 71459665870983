import { Pipe, PipeTransform } from '@angular/core';
import { Resource } from '@ngxp/rest';
import { LinkRelationName } from '../resource/resource.model';
import { hasAnyLink } from '../tech.util';

@Pipe({ name: 'hasAnyLink' })
export class HasAnyLinkPipe implements PipeTransform {
  transform(resource: Resource, ...links: LinkRelationName[]): boolean {
    return hasAnyLink(resource, ...links);
  }
}
