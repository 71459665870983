import {
  StateResource,
  createEmptyStateResource,
  createErrorStateResource,
  createStateResource,
} from '@alfa-client/tech-shared';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { CommandResource, CreateCommandProps } from '../command.model';

import { HttpErrorResponse } from '@angular/common/http';
import * as Actions from './command.actions';

export const COMMAND_FEATURE_KEY = 'CommandState';

export interface CommandPartialState {
  readonly [COMMAND_FEATURE_KEY]: CommandState;
}

export interface CommandState {
  commandByOrderMap: { [order: string]: StateResource<CommandResource> };
}

export const initialState: CommandState = {
  commandByOrderMap: {},
};

const commandReducer: ActionReducer<CommandState, Action> = createReducer(
  initialState,
  on(
    Actions.createCommand,
    (state: CommandState, props: CreateCommandProps): CommandState => ({
      ...state,
      commandByOrderMap: {
        ...state.commandByOrderMap,
        [props.command.order]: createEmptyStateResource(true),
      },
    }),
  ),
  on(
    Actions.createCommandSuccess,
    (state: CommandState, props: Actions.CommandProps): CommandState => ({
      ...state,
      commandByOrderMap: {
        ...state.commandByOrderMap,
        [props.command.order]: createStateResource(props.command),
      },
    }),
  ),
  on(
    Actions.createCommandFailure,
    (state: CommandState, props: Actions.CreateCommandFailureProps): CommandState => ({
      ...state,
      commandByOrderMap: {
        ...state.commandByOrderMap,
        [props.command.order]: createErrorStateResource((<HttpErrorResponse>props.error).error),
      },
    }),
  ),
);

export function reducer(state: CommandState, action: Action): CommandState {
  return commandReducer(state, action);
}
