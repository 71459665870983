/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiRootFacade, ApiRootLinkRel, ApiRootResource } from '@alfa-client/api-root-shared';
import { NavigationService } from '@alfa-client/navigation-shared';
import {
  ApiError,
  MessageCode,
  StateResource,
  createEmptyStateResource,
  createErrorStateResource,
  createStateResource,
  doIfLoadingRequired,
  isApiError,
  isLoadingRequired,
  isNotFound,
  isNotNull,
  isServiceUnavailable,
} from '@alfa-client/tech-shared';
import { VorgangService } from '@alfa-client/vorgang-shared';
import { getUrl, hasLink } from '@ngxp/rest';
import { Resource, ResourceUri } from '@ngxp/rest/lib/resource.model';
import { isNil } from 'lodash-es';
import { BehaviorSubject, Observable, Subscription, combineLatest, of, throwError } from 'rxjs';
import { catchError, filter, first, map, startWith, switchMap, tap } from 'rxjs/operators';
import { UserProfileFacade } from './+state/user-profile.facade';
import { UserProfileListResource, UserProfileResource } from './user-profile.model';
import { UserProfileRepository } from './user-profile.repository';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  private userProfiles = {};
  private userProfileSearchList: BehaviorSubject<StateResource<UserProfileListResource>> =
    new BehaviorSubject(createEmptyStateResource<UserProfileListResource>());
  private currentUser: BehaviorSubject<StateResource<UserProfileResource>> = new BehaviorSubject(
    createEmptyStateResource<UserProfileResource>(),
  );

  private navigationSubscription: Subscription;

  private searchSubscription: Subscription;

  constructor(
    private repository: UserProfileRepository,
    private apiRootFacade: ApiRootFacade,
    private navigationService: NavigationService,
    private vorgangService: VorgangService,
    private facade: UserProfileFacade,
  ) {
    this.listenOnNavigation();
  }

  private listenOnNavigation(): void {
    if (!isNil(this.navigationSubscription)) this.navigationSubscription.unsubscribe();
    this.navigationSubscription = this.navigationService
      .urlChanged()
      .subscribe((params) => this.onNavigation(params));
  }

  onNavigation(params: Params): void {
    if (NavigationService.isVorgangListPage(params)) {
      this.userProfiles = {};
      this.hideUserProfileSearch();
    } else if (
      NavigationService.isVorgangDetailPage(params, VorgangService.VORGANG_WITH_EINGANG_URL)
    ) {
      this.userProfiles = {};
    }
  }

  public getAssignedUserProfile(
    assignedTo: Resource,
    linkRel: string,
  ): Observable<StateResource<UserProfileResource>> {
    const uri: ResourceUri = this.getUserProfileUri(assignedTo, linkRel);

    this.createIfNotExist(uri);

    doIfLoadingRequired(this.userProfiles[uri].value, () => {
      this.userProfiles[uri].next({ ...this.userProfiles[uri].value, loading: true });
      this.loadUserProfile(assignedTo, linkRel);
    });
    return this.userProfiles[uri].asObservable();
  }

  public getCurrentUser(): Observable<StateResource<UserProfileResource>> {
    return combineLatest([this.getCurrentUserFromState(), this.apiRootFacade.getApiRoot()]).pipe(
      filter(([, apiRoot]) => isNotNull(apiRoot.resource)),
      tap(([currentUser, apiRoot]) => this.handleGetCurrentUser(currentUser, apiRoot.resource)),
      map(([currentUser]) => currentUser),
      startWith(createEmptyStateResource<UserProfileResource>(true)),
    );
  }

  getCurrentUserFromState(): Observable<StateResource<UserProfileResource>> {
    return this.currentUser.asObservable();
  }

  handleGetCurrentUser(
    currentUser: StateResource<UserProfileResource>,
    apiRoot: ApiRootResource,
  ): void {
    if (hasLink(apiRoot, ApiRootLinkRel.CURRENT_USER) && isLoadingRequired(currentUser)) {
      this.loadCurrentUser(apiRoot);
    }
  }

  loadCurrentUser(apiRoot: ApiRootResource): void {
    this.setCurrentUserLoading();
    this.repository
      .getCurrentUser(apiRoot)
      .pipe(
        catchError((errorResponse) => this.handleError(errorResponse.error)),
        first(),
      )
      .subscribe((currentUser: ApiError | UserProfileResource) =>
        this.setCurrentUser(this.createStateResourceForUpdate(currentUser)),
      );
  }

  setCurrentUserLoading(): void {
    this.currentUser.next(createEmptyStateResource<UserProfileResource>(true));
  }

  setCurrentUser(userProfile: StateResource<UserProfileResource>): void {
    this.currentUser.next(userProfile);
  }

  createIfNotExist(uri: ResourceUri): void {
    if (isNil(this.userProfiles[uri]))
      this.userProfiles[uri] = new BehaviorSubject(createEmptyStateResource<UserProfileResource>());
  }

  loadUserProfile(vorgang: Resource, linkRel: string): void {
    this.repository
      .getUserProfile(vorgang, linkRel)
      .pipe(
        catchError((errorResponse) => this.handleError(errorResponse.error)),
        first(),
      )
      .subscribe((response: ApiError | UserProfileResource) =>
        this.updateUserProfile(vorgang, linkRel, this.createStateResourceForUpdate(response)),
      );
  }

  handleError(errorResponse: HttpErrorResponse): Observable<ApiError> {
    return of(this.handleErrorByStatus(errorResponse));
  }

  handleErrorByStatus(error: HttpErrorResponse): ApiError {
    if (isNotFound(error.status) || isServiceUnavailable(error.status)) {
      return error.error;
    }
    if (this.isNetworkError(error.status)) {
      return this.buildServiceUnavailableApiError();
    }
    throwError({ error });
  }

  private isNetworkError(status: number): boolean {
    return status == 0;
  }

  private buildServiceUnavailableApiError(): ApiError {
    return <ApiError>{ issues: [{ messageCode: MessageCode.SERVICE_UNAVAILABLE }] };
  }

  createStateResourceForUpdate(
    response: ApiError | UserProfileResource,
  ): StateResource<UserProfileResource> {
    if (isApiError(response)) {
      return createErrorStateResource(<ApiError>response);
    } else {
      return createStateResource(<UserProfileResource>response);
    }
  }

  updateUserProfile(
    vorgang: Resource,
    linkRel: string,
    userProfile: StateResource<UserProfileResource>,
  ): void {
    //TOCHECK Potentielle Fehlerstelle: "cannot read 'next' of null/undefined"
    this.userProfiles[this.getUserProfileUri(vorgang, linkRel)].next(userProfile);
  }

  private getUserProfileUri(vorgang: Resource, linkRel: string): ResourceUri {
    return getUrl(vorgang, linkRel);
  }

  public getSearchedUserProfiles(): Observable<StateResource<UserProfileListResource>> {
    return this.userProfileSearchList.asObservable();
  }

  public search(searchBy: string): Observable<StateResource<UserProfileListResource>> {
    //TODO Im FormService unterscheiden, ob der Suchbegriff leer ist und direkt die "clearSearchList" Methode vom Service aufrufen.
    if (isNil(searchBy)) {
      this.clearSearchList();
    } else {
      this.searchSubscription = this.vorgangService
        .getVorgangWithEingang()
        .pipe(
          filter((stateResource) => stateResource.loaded),
          switchMap((vorgangStateResource) =>
            this.repository.search(vorgangStateResource.resource, searchBy),
          ),
        )
        .subscribe((userProfileList) => {
          this.userProfileSearchList.next(createStateResource(userProfileList));
          setTimeout(() => this.searchSubscription.unsubscribe(), 0);
        });
    }
    return this.userProfileSearchList.asObservable();
  }

  public clearSearchList() {
    this.userProfileSearchList.next(createEmptyStateResource<UserProfileListResource>());
  }

  public getUserProfileSearchVisibility(): Observable<boolean> {
    return this.facade.getUserProfileSearchVisibility();
  }

  public showUserProfileSearch(): void {
    this.facade.showUserProfileSearch();
  }

  public hideUserProfileSearch(): void {
    this.facade.hideUserProfileSearch();
    this.userProfileSearchList.next(createEmptyStateResource<UserProfileListResource>());
  }
}
