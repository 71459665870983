/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Injectable } from '@angular/core';
import { ApiRootLinkRel, ApiRootResource } from '@alfa-client/api-root-shared';
import { SkipInterceptor } from '@alfa-client/tech-shared';
import { VorgangWithEingangLinkRel, VorgangWithEingangResource } from '@alfa-client/vorgang-shared';
import { Resource, ResourceFactory, ResourceUri, getUrl } from '@ngxp/rest';
import { Observable } from 'rxjs';
import { UserProfileListResource, UserProfileResource } from './user-profile.model';

@Injectable({ providedIn: 'root' })
export class UserProfileRepository {
  static SEARCH_PARAM: string = 'searchBy';

  constructor(private resourceFactory: ResourceFactory) {}

  @SkipInterceptor()
  public getUserProfile(resource: Resource, linkRel: string): Observable<UserProfileResource> {
    return this.resourceFactory.from(resource).get(linkRel);
  }

  @SkipInterceptor()
  public getCurrentUser(apiRootResource: ApiRootResource): Observable<UserProfileResource> {
    return this.resourceFactory.from(apiRootResource).get(ApiRootLinkRel.CURRENT_USER);
  }

  @SkipInterceptor()
  public search(
    vorgangWithEingang: VorgangWithEingangResource,
    searchBy: string,
  ): Observable<UserProfileListResource> {
    return this.resourceFactory.fromId(this.buildSearchByUrl(vorgangWithEingang, searchBy)).get();
  }

  buildSearchByUrl(vorgangWithEingang: VorgangWithEingangResource, searchBy: string): ResourceUri {
    return getUrl(vorgangWithEingang, VorgangWithEingangLinkRel.SEARCH_USER_PROFILES).replace(
      '{' + UserProfileRepository.SEARCH_PARAM + '}',
      searchBy,
    );
  }
}
