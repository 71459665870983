import { Resource } from '@ngxp/rest';
import { Observable, filter, map } from 'rxjs';
import { StateResource, hasStateResourceError, isLoaded } from './resource.util';

export function filterIsLoadedOrHasError<T>(): (
  source: Observable<StateResource<T>>,
) => Observable<StateResource<T>> {
  return (source: Observable<StateResource<T>>): Observable<StateResource<T>> => {
    return source.pipe(
      filter(
        (stateResource: StateResource<T>) => hasStateResourceError(stateResource) || isLoaded(stateResource),
      ),
    );
  };
}

export function mapToResource<T>(): (source: Observable<StateResource<T>>) => Observable<T> {
  return (source: Observable<StateResource<T>>): Observable<T> => {
    return source.pipe(map((stateResource: StateResource<T>) => stateResource.resource));
  };
}

export function mapToFirst<T extends Resource, B extends Resource>(): (
  source: Observable<[StateResource<T>, B]>,
) => Observable<StateResource<T>> {
  return (source: Observable<[StateResource<T>, B]>): Observable<StateResource<T>> => {
    return source.pipe(map(([stateResource]) => stateResource));
  };
}
