/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Inject, Injectable } from '@angular/core';
import { ApiRootLinkRel, ApiRootResource } from '@alfa-client/api-root-shared';
import { ENVIRONMENT_CONFIG } from '@alfa-client/environment-shared';
import { HttpService, ResourceFactory, ResourceUri } from '@ngxp/rest';
import { Environment } from 'libs/environment-shared/src/lib/environment.model';
import { Observable } from 'rxjs';
import { DownloadTokenRequest } from './api-root.model';

@Injectable()
export class ApiRootRepository {
  constructor(
    private httpService: HttpService,
    private resourceFactory: ResourceFactory,
    @Inject(ENVIRONMENT_CONFIG) private environmentConfig: Environment,
  ) {}

  public loadApiRoot(): Observable<ApiRootResource> {
    return this.httpService.get(this.environmentConfig.remoteHost);
  }

  public downloadToken(apiRootResource: ApiRootResource, fileUri: ResourceUri): Observable<any> {
    return this.resourceFactory
      .from(apiRootResource)
      .post(ApiRootLinkRel.DOWNLOAD_TOKEN, <DownloadTokenRequest>{ fileId: fileUri });
  }
}
