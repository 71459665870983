import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';
import { InvalidParam, ProblemDetail } from '../tech.model';
import { EMPTY_STRING } from '../tech.util';
import { getMessageForInvalidParam } from '../validation/tech.validation.util';

@Pipe({ name: 'convertProblemDetailToErrorMessages' })
export class ConvertProblemDetailToErrorMessagesPipe implements PipeTransform {
  transform(value: ProblemDetail) {
    if (isNil(value)) {
      return [];
    }
    return value.invalidParams.map((invalidParam: InvalidParam) =>
      getMessageForInvalidParam(EMPTY_STRING, invalidParam),
    );
  }
}
