import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ods-aria-live-region',
  standalone: true,
  imports: [CommonModule],
  template: `<span aria-live="polite" class="sr-only" role="status">{{ text }}</span>`,
})
export class AriaLiveRegionComponent {
  @Input() text: string = '';
}
