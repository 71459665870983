/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HttpErrorResponse } from '@angular/common/http';
import { ApiRootResource } from '@alfa-client/api-root-shared';
import { LoadBinaryFileListSuccessProps } from '@alfa-client/binary-file-shared';
import {
  CommandStateResourceProps,
  LoadCommandListSuccessProps,
} from '@alfa-client/command-shared';
import {
  ApiErrorAction,
  ResourceUriProps,
  TypedActionCreator,
  TypedActionCreatorWithProps,
} from '@alfa-client/tech-shared';
import { createAction, props } from '@ngrx/store';
import {
  AdditionalActions,
  VorgangListResource,
  VorgangWithEingangResource,
} from '../vorgang.model';

export interface SearchVorgaengeByProps {
  apiRoot: ApiRootResource;
  searchString: string;
  linkRel: string;
}

export interface StringBasedProps {
  string: string;
}

export interface ApiRootAction {
  apiRoot: ApiRootResource;
}

export interface ApiRootWithLinkRelProps {
  apiRoot: ApiRootResource;
  linkRel: string;
}

export interface HttpErrorAction {
  httpErrorResponse: HttpErrorResponse;
}

export interface VorgangListAction {
  vorgangList: VorgangListResource;
}

export interface VorgangWithEingangAction {
  vorgangWithEingang: VorgangWithEingangResource;
}

export interface VorgangUriWithContextProps extends ResourceUriProps {
  additionalActions?: AdditionalActions;
}

//VorgangList
export const noOperation: TypedActionCreator = createAction('[Vorgang-Routing] No Operation');

export const loadVorgangList: TypedActionCreatorWithProps<ApiRootWithLinkRelProps> = createAction(
  '[Vorgang] Load VorgangList',
  props<ApiRootWithLinkRelProps>(),
);
export const loadVorgangListSuccess: TypedActionCreatorWithProps<VorgangListAction> = createAction(
  '[Vorgang] Load VorgangList Success',
  props<VorgangListAction>(),
);
export const loadVorgangListFailure: TypedActionCreatorWithProps<ApiErrorAction> = createAction(
  '[Vorgang] Load VorgangList Failure',
  props<ApiErrorAction>(),
);

export const searchVorgaengeBy: TypedActionCreatorWithProps<SearchVorgaengeByProps> = createAction(
  '[Vorgang] Search VorgangList',
  props<SearchVorgaengeByProps>(),
);
export const searchVorgaengeBySuccess: TypedActionCreatorWithProps<VorgangListAction> =
  createAction('[Vorgang] Search VorgangList Success', props<VorgangListAction>());
export const searchVorgaengeByFailure: TypedActionCreatorWithProps<HttpErrorAction> = createAction(
  '[Vorgang] Search VorgangList Failure',
  props<HttpErrorAction>(),
);

export const loadNextPage: TypedActionCreator = createAction(
  '[Vorgang] Load next VorgangList page',
);
export const loadNextPageSuccess: TypedActionCreatorWithProps<VorgangListAction> = createAction(
  '[Vorgang] Load next VorgangList page Success',
  props<VorgangListAction>(),
);

//Search
export const searchForPreview: TypedActionCreatorWithProps<StringBasedProps> = createAction(
  '[Vorgang] Search for preview',
  props<StringBasedProps>(),
);
export const searchForPreviewSuccess: TypedActionCreatorWithProps<VorgangListAction> = createAction(
  '[Vorgang] Search for preview Success',
  props<VorgangListAction>(),
);
export const searchForPreviewFailure: TypedActionCreatorWithProps<HttpErrorAction> = createAction(
  '[Vorgang] Search for preview Failure',
  props<HttpErrorAction>(),
);

export const clearSearchPreviewList: TypedActionCreator = createAction(
  '[Vorgang] Clear search preview list',
);
export const clearSearchString: TypedActionCreator = createAction('[Vorgang] Clear search string');

export const setSearchString: TypedActionCreatorWithProps<StringBasedProps> = createAction(
  '[Vorgang] Set search string',
  props<StringBasedProps>(),
);

//VorgangWithEingang
export const loadVorgangWithEingang: TypedActionCreatorWithProps<VorgangUriWithContextProps> =
  createAction('[Vorgang] Load VorgangWithEingang', props<VorgangUriWithContextProps>());
export const loadVorgangWithEingangSuccess: TypedActionCreatorWithProps<VorgangWithEingangAction> =
  createAction('[Vorgang] Load VorgangWithEingang Success', props<VorgangWithEingangAction>());
export const loadVorgangWithEingangFailure: TypedActionCreatorWithProps<ApiErrorAction> =
  createAction('[Vorgang] Load VorgangWithEingang Failure', props<ApiErrorAction>());

export const clearVorgangWithEingang: TypedActionCreator = createAction(
  '[Vorgang] Clear VorgangWithEingang',
);
export const setReloadVorgangWithEingang: TypedActionCreator = createAction(
  '[Vorgang] Set reload at VorgangWithEingang',
);

export const loadPendingCommandList: TypedActionCreatorWithProps<VorgangWithEingangAction> =
  createAction('[Vorgang] Load pending command list', props<VorgangWithEingangAction>());
export const loadPendingCommandListSuccess: TypedActionCreatorWithProps<LoadCommandListSuccessProps> =
  createAction(
    '[Vorgang/API] Load pending command list Success',
    props<LoadCommandListSuccessProps>(),
  );
export const loadPendingCommandListFailure: TypedActionCreatorWithProps<ApiErrorAction> =
  createAction('[Vorgang/API] Load pending command list Failure', props<ApiErrorAction>());

export const loadAttachmentList: TypedActionCreatorWithProps<VorgangWithEingangAction> =
  createAction('[Vorgang] Load AttachmentList', props<VorgangWithEingangAction>());
export const loadAttachmentListSuccess: TypedActionCreatorWithProps<LoadBinaryFileListSuccessProps> =
  createAction('[Vorgang] Load AttachmentList Success', props<LoadBinaryFileListSuccessProps>());
export const loadAttachmentListFailure: TypedActionCreatorWithProps<ApiErrorAction> = createAction(
  '[Vorgang] Load AttachmentList Failure',
  props<ApiErrorAction>(),
);

export const loadRepresentationList: TypedActionCreatorWithProps<VorgangWithEingangAction> =
  createAction('[Vorgang] Load RepresentationList', props<VorgangWithEingangAction>());
export const loadRepresentationListSuccess: TypedActionCreatorWithProps<LoadBinaryFileListSuccessProps> =
  createAction(
    '[Vorgang] Load RepresentationList Success',
    props<LoadBinaryFileListSuccessProps>(),
  );
export const loadRepresentationListFailure: TypedActionCreatorWithProps<ApiErrorAction> =
  createAction('[Vorgang] Load RepresentationList Failure', props<ApiErrorAction>());

export const setForwardingSingleCommand: TypedActionCreatorWithProps<CommandStateResourceProps> =
  createAction('[Vorgang] Set forward command', props<CommandStateResourceProps>());
export const setForwardingSingleCommandLoading: TypedActionCreator = createAction(
  '[Vorgang] Set forward command loading',
);
export const setSendPostfachNachrichtSingleCommand: TypedActionCreatorWithProps<CommandStateResourceProps> =
  createAction('[Vorgang] Set send postfach nachricht command', props<CommandStateResourceProps>());
export const setSendPostfachNachrichtSingleCommandLoading: TypedActionCreator = createAction(
  '[Vorgang] Set send postfach nachricht command loading',
);

export const initAssignUser: TypedActionCreator = createAction('[Vorgang] Init Assign user');

export const exportVorgang: TypedActionCreatorWithProps<VorgangWithEingangAction> = createAction(
  '[Vorgang] Export',
  props<VorgangWithEingangAction>(),
);
export const exportVorgangSuccess: TypedActionCreator = createAction('[Vorgang] Export Success');
export const exportVorgangFailure: TypedActionCreatorWithProps<ApiErrorAction> = createAction(
  '[Vorgang] Export Failure',
  props<ApiErrorAction>(),
);
