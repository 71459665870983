import { Component, Input } from '@angular/core';

@Component({
  selector: 'ozgcloud-icon',
  templateUrl: './ozgcloud-icon.component.html',
  styleUrls: ['./ozgcloud-icon.component.scss'],
})
export class OzgcloudIconComponent {
  @Input() icon: string;
}
