import { Component, Input } from '@angular/core';

@Component({
  selector: 'ozgcloud-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  @Input() headline: string;
  @Input() text: string;
  @Input() icon: string;
  @Input() iconSizeBig: boolean = false;
}
