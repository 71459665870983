import { Component, Input } from '@angular/core';

@Component({
  selector: 'ozgcloud-svgicon',
  templateUrl: './ozgcloud-svgicon.component.html',
  styleUrls: ['./ozgcloud-svgicon.component.scss'],
})
export class OzgcloudSvgIconComponent {
  @Input() svgIcon: string;
}
