import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isClipboardReadSupported } from '@alfa-client/tech-shared';

@Component({
  selector: 'ozgcloud-paste-text-button',
  templateUrl: './ozgcloud-paste-text-button.component.html',
  styleUrls: ['./ozgcloud-paste-text-button.component.scss'],
})
export class OzgcloudPasteTextButtonComponent implements OnInit {
  @Input() tooltip: string;
  @Output() pasteEmitter: EventEmitter<string> = new EventEmitter<string>();

  isPasteSupported = false;

  ngOnInit(): void {
    this.isPasteSupported = isClipboardReadSupported();
  }

  async onPaste(): Promise<void> {
    this.pasteEmitter.emit(await navigator.clipboard.readText());
  }
}
