import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

import { IconVariants, iconVariants } from '../iconVariants';

@Component({
  selector: 'ods-spinner-icon',
  standalone: true,
  imports: [NgClass],
  styles: [':host {@apply flex}'],
  template: `<svg
      xmlns="http://www.w3.org/2000/svg"
      [ngClass]="iconVariants({ size })"
      class="animate-spin fill-primary text-gray-200 dark:text-gray-600"
      aria-hidden="true"
      viewBox="0 0 100 100"
      fill="none"
    >
      <path
        d="M100 50.59c0 27.615-22.386 50.001-50 50.001s-50-22.386-50-50 22.386-50 50-50 50 22.386 50 50Zm-90.919 0c0 22.6 18.32 40.92 40.919 40.92 22.599 0 40.919-18.32 40.919-40.92 0-22.598-18.32-40.918-40.919-40.918-22.599 0-40.919 18.32-40.919 40.919Z"
        fill="currentColor"
      />
      <path
        d="M93.968 39.04c2.425-.636 3.894-3.128 3.04-5.486A50 50 0 0 0 41.735 1.279c-2.474.414-3.922 2.919-3.285 5.344.637 2.426 3.12 3.849 5.6 3.484a40.916 40.916 0 0 1 44.131 25.769c.902 2.34 3.361 3.802 5.787 3.165Z"
      />
    </svg>
    <span class="sr-only">Loading...</span> `,
})
export class SpinnerIconComponent {
  @Input() size: IconVariants['size'] = 'full';

  iconVariants = iconVariants;
}
