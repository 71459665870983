<button
  *ngIf="getNumberOfMenuItems() > 0"
  mat-fab
  extended
  [matMenuTriggerFor]="helpMenu.matMenu"
  data-test-id="help-menu-button"
>
  <div class="flex items-center text-ozggray-800 dark:text-ozggray-300">
    <ozgcloud-icon class="mr-1" icon="help_outline"></ozgcloud-icon>
    <div>Hilfe</div>
  </div>
</button>
<ozgcloud-menu #helpMenu>
  <ozgcloud-menu-item
    *ngIf="apiRootStateResource?.resource | hasLink: apiRootLinkRel.DOCUMENTATIONS"
    headline="Benutzerleitfaden"
    text="Alle Funktionen der Allgemeinen Fachanwendung (Alfa) erklärt."
    icon="pdf_file"
    #benutzerleitfadenMenuItem
  >
    <alfa-documentation
      data-test-id="documentations-component"
      [menuTitle]="benutzerleitfadenMenuItem.headline"
      [url]="apiRootStateResource.resource | getUrl: apiRootLinkRel.DOCUMENTATIONS"
    >
    </alfa-documentation>
  </ozgcloud-menu-item>
</ozgcloud-menu>
