import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';

import { IconVariants, iconVariants } from '../iconVariants';

@Component({
  selector: 'ods-bescheid-upload-icon',
  standalone: true,
  imports: [NgClass],
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-pdf', class)]"
    aria-hidden="true"
    fill="none"
    viewBox="0 0 32 40"
  >
    <path
      d="M32 12 20 0H4C1.8 0 .02 1.8.02 4L0 36c0 2.2 1.78 4 3.98 4H28c2.2 0 4-1.8 4-4V12Zm-4 24H4V4h14v10h10v22Z"
    />
    <path d="M18.273 2.86H2.856v34.285h26.429V13.574H18.273V2.86Z" />
    <path
      fill="#FEFEFE"
      d="M15 24v-8.15l-2.6 2.6L11 17l5-5 5 5-1.4 1.45-2.6-2.6V24h-2Zm-5 4c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 8 26v-3h2v3h12v-3h2v3c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 22 28H10Z"
    />
  </svg>`,
})
export class BescheidUploadIconComponent {
  @Input() size: IconVariants['size'] = 'extra-large';
  @Input() class: string = undefined;

  iconVariants = iconVariants;
  twMerge = twMerge;
}
