import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { CheckIconComponent } from '../../icons/check-icon/check-icon.component';

@Component({
  selector: 'ods-button-toggle',
  standalone: true,
  imports: [CommonModule, CheckIconComponent],
  template: `<button
    class="flex cursor-pointer items-center gap-1 px-3 py-1.5 outline-none hover:bg-neutral-300 focus-visible:bg-neutral-300 dark:hover:bg-neutral-600 dark:focus-visible:bg-neutral-600"
    [ngClass]="{
      'bg-neutral-200 font-bold hover:bg-neutral-200 focus-visible:bg-neutral-200 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:focus-visible:bg-neutral-800':
        isChecked,
    }"
    [value]="value"
    (click)="onClick()"
    (focus)="isFocused = true"
    (blur)="isFocused = false"
    data-test-id="button-toggle"
  >
    <ods-check-icon size="small" *ngIf="isChecked" data-test-id="toggle-check-icon" />
    <p class="text-sm">{{ label }}</p>
  </button>`,
})
export class ButtonToggleComponent {
  @Input({ required: true }) label!: string;
  @Input({ required: true }) value!: string;
  @Input() onClick: () => void;
  @Input() isSelected: (value: string) => boolean = () => false;

  @HostBinding('class.ods-focused') isFocused: boolean = false;
  @HostBinding('class.ods-button-toggle-checked') get isChecked() {
    return this.isSelected(this.value);
  }
}
