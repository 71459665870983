import { Pipe, PipeTransform } from '@angular/core';
import { Resource } from '@ngxp/rest';
import { LinkRelationName } from '../resource/resource.model';
import { notHasAnyLink } from '../tech.util';

@Pipe({ name: 'notHasAnyLink' })
export class NotHasAnyLinkPipe implements PipeTransform {
  transform(resource: Resource, ...links: LinkRelationName[]): boolean {
    return notHasAnyLink(resource, ...links);
  }
}
