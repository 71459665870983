import { Pipe, PipeTransform } from '@angular/core';
import { isString } from 'lodash-es';
import { formatFullDate } from '../date.util';

@Pipe({ name: 'formatFullDatePipe' })
export class FormatFullDatePipe implements PipeTransform {
  transform(date: Date | string): string {
    date = isString(date) ? new Date(date) : date;
    return formatFullDate(date);
  }
}
