/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { BlobWithFileName, HttpHeader, HttpMethod } from './tech.model';

export function isForbidden(status: HttpStatusCode): boolean {
  return status == HttpStatusCode.Forbidden;
}

export function isUnauthorized(status: HttpStatusCode): boolean {
  return status == HttpStatusCode.Unauthorized;
}

export function isUnprocessableEntity(status: HttpStatusCode): boolean {
  return status == HttpStatusCode.UnprocessableEntity;
}

export function isServerError(status: HttpStatusCode): boolean {
  return status >= 500 && status < 600 && !isConnectionTimeout(status);
}

export function isConnectionTimeout(status: HttpStatusCode): boolean {
  return isBadGateway(status) || isServiceUnavailable(status) || isGatewayTimeout(status);
}

function isBadGateway(status: HttpStatusCode): boolean {
  return status == HttpStatusCode.BadGateway;
}

export function isServiceUnavailable(status: HttpStatusCode): boolean {
  return status == HttpStatusCode.ServiceUnavailable;
}

function isGatewayTimeout(status: HttpStatusCode): boolean {
  return status == HttpStatusCode.GatewayTimeout;
}

export function isNotFound(status: HttpStatusCode): boolean {
  return status == HttpStatusCode.NotFound;
}

export function isChangingDataRequest(request: HttpRequest<unknown>): boolean {
  return (
    request.method === HttpMethod.POST ||
    request.method === HttpMethod.PUT ||
    request.method === HttpMethod.PATCH ||
    request.method === HttpMethod.DELETE
  );
}

export function existRequestHeader(request: HttpRequest<unknown>, key: string) {
  return request.headers.has(key);
}

export function addRequestHeader(
  request: HttpRequest<unknown>,
  key: string,
  token: string,
): HttpRequest<unknown> {
  return request.clone({ headers: request.headers.set(key, token) });
}

export function buildBlobWithFileName(response: HttpResponse<Blob>): BlobWithFileName {
  return { blob: response.body, fileName: extractFileNameFromResponse(response) };
}

function extractFileNameFromResponse(response: HttpResponse<Blob>): string {
  const header: string = getContentDispositionHeader(response);
  return header.substring(header.lastIndexOf('filename') + 9, header.length);
}

function getContentDispositionHeader(response: HttpResponse<Blob>): string {
  return response.headers.get(HttpHeader.CONTENT_DISPOSITION);
}
