import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ListItemComponent } from './list-item/list-item.component';

@Component({
  selector: 'ods-list',
  standalone: true,
  imports: [CommonModule, ListItemComponent],
  template: `
    <ul class="divide-y divide-gray-300 rounded-md bg-background-50 text-text shadow-sm ring-1 ring-gray-300 empty:hidden">
      <ng-content />
    </ul>
  `,
})
export class ListComponent {}
