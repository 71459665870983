/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  ApiErrorAction,
  createEmptyStateResource,
  createErrorStateResource,
  createStateResource,
  StateResource,
} from '@alfa-client/tech-shared';
import { Action, createReducer, on } from '@ngrx/store';
import { ApiRootResource } from '../api-root.model';
import { ApiRootProps } from './api-root.actions';

import * as ApiRootActions from './api-root.actions';

export const API_ROOT_FEATURE_KEY = 'ApiRootState';

export interface ApiRootState {
  apiRoot: StateResource<ApiRootResource>;
}

export interface ApiRootPartialState {
  readonly [API_ROOT_FEATURE_KEY]: ApiRootState;
}

export const initialState: ApiRootState = {
  apiRoot: createEmptyStateResource(),
};

const apiRootReducer = createReducer(
  initialState,
  on(ApiRootActions.loadApiRoot, (state: ApiRootState) => ({
    ...state,
    apiRoot: { ...state.apiRoot, loading: true },
  })),
  on(ApiRootActions.loadApiRootSuccess, (state: ApiRootState, props: ApiRootProps) => ({
    ...state,
    apiRoot: createStateResource(props.apiRoot),
  })),
  on(ApiRootActions.loadApiRootFailure, (state: ApiRootState, props: ApiErrorAction) => ({
    ...state,
    apiRoot: createErrorStateResource(props.apiError),
  })),
);

export function reducer(state: ApiRootState, action: Action) {
  return apiRootReducer(state, action);
}
