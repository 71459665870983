import {
  ApiErrorAction,
  StateResource,
  createEmptyStateResource,
  createErrorStateResource,
  createStateResource,
} from '@alfa-client/tech-shared';
import { Action, createReducer, on } from '@ngrx/store';
import { HintListResource } from '../hint.model';
import { HintListProps } from './hint.actions';

import * as HintActions from './hint.actions';

export const HINT_FEATURE_KEY = 'HintState';

export interface HintState {
  hintList: StateResource<HintListResource>;
}

export interface HintPartialState {
  readonly [HINT_FEATURE_KEY]: HintState;
}

export const initialState: HintState = {
  hintList: createEmptyStateResource<HintListResource>(),
};

const hintReducer = createReducer(
  initialState,
  on(HintActions.loadHintListSuccess, (state: HintState, props: HintListProps) => ({
    ...state,
    hintList: createStateResource(props.hintList),
  })),
  on(HintActions.loadHintListFailed, (state: HintState, props: ApiErrorAction) => ({
    ...state,
    hintList: createErrorStateResource(props.apiError),
  })),
);

export function reducer(state: HintState, action: Action) {
  return hintReducer(state, action);
}
