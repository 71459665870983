import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'ods-attachment-wrapper',
  standalone: true,
  imports: [CommonModule],
  styles: [
    ':host {@apply flex flex-col overflow-hidden rounded-md shadow shadow-grayborder border border-grayborder empty:hidden}',
  ],
  template: `<ng-content></ng-content>`,
})
export class AttachmentWrapperComponent {}
