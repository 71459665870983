import { Component, Input } from '@angular/core';

@Component({
  selector: 'ozgcloud-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss'],
})
export class DownloadButtonComponent {
  @Input() icon: string;
  @Input() url: string;
  @Input() text: string;
  @Input() targetName: string;
}
