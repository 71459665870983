import { TechSharedModule } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'ods-nav-item',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, TechSharedModule],
  template: `<a
    [routerLink]="path"
    routerLinkActive="bg-selected-light border-selected"
    class="flex min-h-8 items-center gap-2 rounded-2xl border border-transparent
    px-4 py-2 outline-2 outline-offset-2 outline-focus hover:border-primary
    focus-visible:border-background-200 focus-visible:outline"
    [attr.data-test-id]="'link-path-' + path | convertForDataTest"
  >
    <ng-content select="[icon]" />
    <p class="text-left text-sm text-text" [attr.data-test-id]="'caption-' + caption | convertForDataTest">{{ caption }}</p>
  </a>`,
})
export class NavItemComponent {
  @Input({ required: true }) caption!: string;
  @Input() path: string;

  @HostBinding('attr.role') role: string = 'menuitem';
}
