<!--

    Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<div class="notification-dialog" data-test-id="connection-timeout-retry-fail-dialog">
  <h2 mat-dialog-title>
    <mat-icon data-test-id="icon">error_outline</mat-icon>
    <span data-test-id="header">Zeitüberschreitung der Verbindung</span>
  </h2>
  <mat-dialog-content>
    <p data-test-id="message">
      Auch nach weiteren Versuchen konnte keine Verbindung aufgebaut werden. <br />
      Bitte wenden Sie sich an Ihren Administrator.
    </p>
    <ozgcloud-stroked-button-with-spinner
      tabindex="-1"
      class="button"
      icon="refresh"
      text="Reload"
      data-test-id="reload-button"
      (click)="reload()"
    >
    </ozgcloud-stroked-button-with-spinner>
  </mat-dialog-content>
</div>
