<!--

    Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<div class="line name-date">
  <span class="name" data-test-id="preview-list-item-vorgang-name">{{ vorgang.name }}</span>
  <div class="date">
    <mat-icon svgIcon="incoming"></mat-icon>
    <span data-test-id="preview-list-item-created-at">{{
      vorgang.createdAt | formatToPrettyDate
    }}</span>
  </div>
</div>
<div class="line full-width">
  <alfa-vorgang-nummer [vorgang]="vorgang"></alfa-vorgang-nummer>
</div>
<div class="line full-width">
  <div class="aktenzeichen" data-test-id="preview-list-item-vorgang-aktenzeichen">
    <mat-icon svgIcon="az"></mat-icon>
    <span alfa-aktenzeichen [vorgang]="vorgang"></span>
  </div>
</div>
