import {
  EMPTY_STRING,
  ResourceRepository,
  ResourceService,
  ResourceServiceConfig,
  StateResource,
  createEmptyStateResource,
} from '@alfa-client/tech-shared';
import { Resource } from '@ngxp/rest';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommandResource, CreateCommandProps } from './command.model';
import { CommandService } from './command.service';

export class CommandResourceService<B extends Resource, T extends Resource> extends ResourceService<B, T> {
  deleteStateCommandResource: BehaviorSubject<StateResource<CommandResource>> = new BehaviorSubject<
    StateResource<CommandResource>
  >(createEmptyStateResource());

  constructor(
    protected config: ResourceServiceConfig<B>,
    protected repository: ResourceRepository,
    private commandService: CommandService,
  ) {
    super(config, repository);
  }

  doSave(resource: T, toSave: unknown): Observable<T> {
    throw new Error('Method not implemented.');
  }

  doPatch(resource: T, toPatch: unknown): Observable<T> {
    throw new Error('Method not implemented.');
  }

  public delete(): Observable<StateResource<CommandResource>> {
    return this.commandService.createCommandByProps(this.buildDeleteCommandProps());
  }

  private buildDeleteCommandProps(): CreateCommandProps {
    return {
      resource: this.stateResource.value.resource,
      linkRel: this.config.delete.linkRel,
      command: { order: this.config.delete.order, body: null },
      snackBarMessage: EMPTY_STRING,
    };
  }
}
