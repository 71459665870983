import { Component, Input } from '@angular/core';

@Component({
  selector: 'ozgcloud-open-url-button',
  templateUrl: './open-url-button.component.html',
  styleUrls: ['./open-url-button.component.scss'],
})
export class OpenUrlButtonComponent {
  @Input() icon: string;
  @Input() url: string;
  @Input() text: string;
  @Input() targetName: string;
  @Input() tooltip: string;
}
