<h1 mat-dialog-title data-test-id="dialog-header">
  {{ data.title }}
</h1>
<div mat-dialog-content>
  <p data-test-id="dialog-message">{{ data.message }}</p>
</div>
<div mat-dialog-actions>
  <ozgcloud-stroked-button-with-spinner
    dataTestId="dialog-confirmation-button"
    [mat-dialog-close]="true"
    svgIcon="{{ data.okSvgIcon }}"
    text="{{ data.okText }}"
  >
  </ozgcloud-stroked-button-with-spinner>

  <ozgcloud-stroked-button-with-spinner
    dataTestId="dialog-cancel-button"
    (click)="onNoClick()"
    text="Abbrechen"
  >
  </ozgcloud-stroked-button-with-spinner>
</div>
