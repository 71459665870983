import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';

import { IconVariants, iconVariants } from '../iconVariants';

@Component({
  selector: 'ods-attachment-icon',
  standalone: true,
  imports: [NgClass],
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-primary', class)]"
    aria-hidden="true"
    viewBox="0 0 16 25"
  >
    <path
      d="M15.874 17c0 2.08-.73 3.85-2.19 5.31-1.46 1.46-3.23 2.19-5.31 2.19-2.08 0-3.85-.73-5.31-2.19C1.604 20.85.874 19.08.874 17V5.9c0-1.5.525-2.775 1.575-3.825C3.499 1.025 4.774.5 6.274.5s2.775.525 3.825 1.575c1.05 1.05 1.575 2.325 1.575 3.825v10.5c0 .92-.32 1.7-.96 2.34-.64.64-1.42.96-2.34.96-.92 0-1.7-.32-2.34-.96-.64-.64-.96-1.42-.96-2.34V5.3h2.4v11.1c0 .26.085.475.255.645.17.17.385.255.645.255s.475-.085.645-.255a.874.874 0 0 0 .255-.645V5.9c-.02-.84-.315-1.55-.885-2.13-.57-.58-1.275-.87-2.115-.87-.84 0-1.55.29-2.13.87-.58.58-.87 1.29-.87 2.13V17c-.02 1.42.47 2.625 1.47 3.615 1 .99 2.21 1.485 3.63 1.485 1.4 0 2.59-.495 3.57-1.485.98-.99 1.49-2.195 1.53-3.615V5.3h2.4V17Z"
    />
  </svg>`,
})
export class AttachmentIconComponent {
  @Input() size: IconVariants['size'] = 'medium';
  @Input() class: string = undefined;

  iconVariants = iconVariants;
  twMerge = twMerge;
}
