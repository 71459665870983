import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ExclamationIconComponent } from '../../icons/exclamation-icon/exclamation-icon.component';

@Component({
  selector: 'ods-error-message',
  standalone: true,
  imports: [CommonModule, ExclamationIconComponent],
  styles: [':host {@apply flex text-error my-2 text-sm items-center font-medium}'],
  template: `<ods-exclamation-icon class="mr-1"></ods-exclamation-icon>
    <div class="flex-grow break-all">
      {{ text }}
      <br *ngIf="subText" aria-hidden="true" />
      {{ subText }}
    </div> `,
})
export class ErrorMessageComponent {
  @Input({ required: true }) text!: string;
  @Input() subText: string = '';
}
