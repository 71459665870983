/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  CommandOrder,
  CommandResource,
  tapOnCommandSuccessfullyDone,
} from '@alfa-client/command-shared';
import { StateResource, isNotNil } from '@alfa-client/tech-shared';
import { Injectable } from '@angular/core';
import { Observable, filter } from 'rxjs';
import { VorgangFacade } from './+state/vorgang.facade';
import { VorgangWithEingangResource } from './vorgang.model';
import { VorgangService } from './vorgang.service';
import {
  createAbschliessenCommand,
  createAnnehmenCommand,
  createBearbeitenCommand,
  createBescheidenCommand,
  createVerwerfenCommand,
  createWiedereroeffnenCommand,
  createZurueckholenCommand,
  createZurueckstellenCommand,
} from './vorgang.util';

@Injectable({ providedIn: 'root' })
export class VorgangCommandService {
  constructor(
    private facade: VorgangFacade,
    private vorgangService: VorgangService,
  ) {}

  public getAnnehmenCommand(): Observable<StateResource<CommandResource>> {
    return this.facade.getStatusCommand(CommandOrder.VORGANG_ANNEHMEN);
  }

  public annehmen(vorgang: VorgangWithEingangResource): void {
    this.facade.annehmen(vorgang, createAnnehmenCommand());
  }

  public getBearbeitenCommand(): Observable<StateResource<CommandResource>> {
    return this.facade.getStatusCommand(CommandOrder.VORGANG_BEARBEITEN);
  }

  public bearbeiten(vorgang: VorgangWithEingangResource): void {
    this.facade.bearbeiten(vorgang, createBearbeitenCommand());
  }

  public getBeschiedenCommand(): Observable<StateResource<CommandResource>> {
    return this.facade.getStatusCommand(CommandOrder.VORGANG_BESCHEIDEN);
  }

  public bescheiden(
    vorgang: VorgangWithEingangResource,
  ): Observable<StateResource<CommandResource>> {
    this.facade.bescheiden(vorgang, createBescheidenCommand());
    return this.doReloadAfter(this.getBeschiedenCommand());
  }

  public getVerwerfenCommand(): Observable<StateResource<CommandResource>> {
    return this.facade.getStatusCommand(CommandOrder.VORGANG_VERWERFEN);
  }

  public verwerfen(vorgang: VorgangWithEingangResource): void {
    this.facade.verwerfen(vorgang, createVerwerfenCommand());
  }

  public getWiedereroeffnenCommand(): Observable<StateResource<CommandResource>> {
    return this.facade.getStatusCommand(CommandOrder.VORGANG_WIEDEREROEFFNEN);
  }

  public wiedereroeffnen(vorgang: VorgangWithEingangResource): void {
    this.facade.wiedereroeffnen(vorgang, createWiedereroeffnenCommand());
  }

  public getZurueckholenCommand(): Observable<StateResource<CommandResource>> {
    return this.facade.getStatusCommand(CommandOrder.VORGANG_ZURUECKHOLEN);
  }

  public zurueckholen(vorgang: VorgangWithEingangResource): void {
    this.facade.zurueckholen(vorgang, createZurueckholenCommand());
  }

  public getZurueckstellenCommand(): Observable<StateResource<CommandResource>> {
    return this.facade.getStatusCommand(CommandOrder.VORGANG_ZURUECKSTELLEN);
  }

  public zurueckstellen(vorgang: VorgangWithEingangResource): void {
    this.facade.zurueckstellen(vorgang, createZurueckstellenCommand());
  }

  public abschliessen(
    vorgang: VorgangWithEingangResource,
  ): Observable<StateResource<CommandResource>> {
    this.facade.abschliessen(vorgang, createAbschliessenCommand());
    return this.doReloadAfter(this.getAbschliessenCommand());
  }

  doReloadAfter(
    statusCommand: Observable<StateResource<CommandResource>>,
  ): Observable<StateResource<CommandResource>> {
    return statusCommand.pipe(
      filter(isNotNil),
      tapOnCommandSuccessfullyDone((commandStateResource: StateResource<CommandResource>) =>
        this.vorgangService.reloadVorgang(commandStateResource.resource),
      ),
    );
  }

  public getAbschliessenCommand(): Observable<StateResource<CommandResource>> {
    return this.facade.getStatusCommand(CommandOrder.VORGANG_ABSCHLIESSEN);
  }

  public getRevokeCommand(): Observable<StateResource<CommandResource>> {
    return this.facade.getRevokeCommand();
  }
}
