/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Injectable } from '@angular/core';
import { UserProfileLinkRel, UserProfileResource } from '@alfa-client/user-profile-shared';
import { ResourceFactory } from '@ngxp/rest';
import { Observable } from 'rxjs';
import { UserSettingsLinkRel } from './user-settings.linkrel';
import { UserSettings, UserSettingsResource } from './user-settings.model';

@Injectable({ providedIn: 'root' })
export class UserSettingsRepository {
  constructor(private resourceFactory: ResourceFactory) {}

  public getUserSettings(
    userProfileResource: UserProfileResource,
  ): Observable<UserSettingsResource> {
    return this.resourceFactory.from(userProfileResource).get(UserProfileLinkRel.SETTINGS);
  }

  public setUserSettings(
    userSettingsResource: UserSettingsResource,
    userSettings: UserSettings,
  ): Observable<UserSettingsResource> {
    return this.resourceFactory
      .from(userSettingsResource)
      .patch(UserSettingsLinkRel.EDIT, userSettings);
  }
}
