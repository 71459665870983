import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { isNull } from 'lodash-es';
import { HINT_FEATURE_KEY, HintState } from './hint.reducer';

import * as HintUtil from '../hint.util';

export const getHintState: MemoizedSelector<object, HintState> =
  createFeatureSelector<HintState>(HINT_FEATURE_KEY);

export const hasUserNoOrganisationsEinheitIdHint: MemoizedSelector<HintState, boolean> =
  createSelector(getHintState, (state: HintState) => {
    if (isNull(state.hintList.resource)) {
      return false;
    }
    return HintUtil.hasUserNoOrganisationsEinheitIdHint(state.hintList.resource);
  });
