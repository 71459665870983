<!--

    Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<form
  (ngSubmit)="submit()"
  [formGroup]="formService.form"
  [class.search-field--open]="searchAutoComplete.isOpen"
  class="search-field"
  data-test-id="search-form"
>
  <span aria-live="polite" class="sr-only" role="status">{{ searchResultPreviewLabel }}</span>
  <button
    #searchSubmitButton
    type="submit"
    data-test-id="search-button"
    mat-icon-button
    aria-label="Vorgang suchen"
  >
    <mat-icon matPrefix class="search-icon">search</mat-icon>
  </button>
  <mat-form-field floatLabel="never" subscriptSizing="dynamic">
    <input
      matInput
      data-test-id="search-input"
      #searchInput
      placeholder="Vorgang suchen"
      aria-label="Vorgang suchen"
      type="text"
      maxlength="50"
      [matAutocomplete]="searchAutoComplete"
      [formControlName]="formService.SEARCH_FIELD"
      name="searchString"
      (focusout)="focusOut($event)"
      (focusin)="focusIn()"
    />

    <mat-autocomplete
      #searchAutoComplete="matAutocomplete"
      class="vorgang-search"
      (optionSelected)="formService.submitByPreviewList($event.option.value, searchString)"
    >
      <ozgcloud-spinner
        [stateResource]="vorgangListPreview"
        [class.autocomplete-spinner]="vorgangListPreview.loading"
      ></ozgcloud-spinner>

      <ng-container *ngIf="vorgangListPreview.resource" data-test-id="search-preview-list">
        <mat-option
          *ngFor="
            let vorgang of vorgangListPreview.resource
              | toEmbeddedResources: vorgangListLinkRel.VORGANG_HEADER_LIST
          "
          [value]="vorgang"
        >
          <ng-container *ngIf="vorgang | hasLink: vorgangHeaderLinkRel.VORGANG_WITH_EINGANG">
            <alfa-vorgang-search-autocomplete-options-content
              [vorgang]="vorgang"
              [attr.data-test-id]="'search-preview-list-item-' + vorgang.name | convertForDataTest"
            >
            </alfa-vorgang-search-autocomplete-options-content>
          </ng-container>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>

  <alfa-vorgang-search-clear-button
    *ngIf="formService.hasSearchString"
    (clear)="handleSearchClearButton()"
    data-test-id="vorgang-search-clear-button"
  ></alfa-vorgang-search-clear-button>
</form>
