import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as UserProfileActions from './user-profile.actions';
import * as UserProfileSelectors from './user-profile.selectors';

@Injectable()
export class UserProfileFacade {
  constructor(private readonly store: Store) {}

  public getUserProfileSearchVisibility(): Observable<boolean> {
    return this.store.select(UserProfileSelectors.userProfileSearchVisibilty);
  }

  public showUserProfileSearch(): void {
    this.store.dispatch(UserProfileActions.showUserProfileSearch());
  }

  public hideUserProfileSearch(): void {
    this.store.dispatch(UserProfileActions.hideUserProfileSearch());
  }
}
