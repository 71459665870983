import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'ods-navbar',
  standalone: true,
  imports: [CommonModule],
  template: `<nav class="h-full w-72 bg-ozggray p-4">
    <div class="flex flex-col gap-2" role="menubar">
      <ng-content />
    </div>
  </nav>`,
})
export class NavbarComponent {}
