import { ApiRootLinkRel, ApiRootResource } from '@alfa-client/api-root-shared';
import { StateResource } from '@alfa-client/tech-shared';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'alfa-user-settings-dropdown',
  templateUrl: './user-settings-dropdown.component.html',
})
export class UserSettingsDropdownComponent {
  @Input() apiRoot: StateResource<ApiRootResource>;
  readonly apiRootLinkRel = ApiRootLinkRel;
}
