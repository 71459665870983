/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ListResource } from '@alfa-client/tech-shared';
import { Resource } from '@ngxp/rest';

export interface CreateCommand {
  order: CommandOrder | any;
  body?: any | CommandBody;
  relationId?: string;
}

export interface CommandBody {
  item: any;
}

export interface Command extends CreateCommand {
  createdAt: Date;
  createdByName?: string;
  status: CommandStatus;
  finishedAt: Date;
  errorMessage: string;
}

export interface CommandResource extends Command, Resource {}

export interface CommandListResource extends ListResource {}

export enum CommandStatus {
  FINISHED = 'FINISHED',
  PENDING = 'PENDING',
  REVOKED = 'REVOKED',
  ERROR = 'ERROR',
}

export enum CommandOrder {
  ASSIGN_USER = 'ASSIGN_USER',
  CREATE_KOMMENTAR = 'CREATE_KOMMENTAR',
  EDIT_KOMMENTAR = 'EDIT_KOMMENTAR',
  CREATE_WIEDERVORLAGE = 'CREATE_WIEDERVORLAGE',
  EDIT_WIEDERVORLAGE = 'EDIT_WIEDERVORLAGE',
  FORWARD_FAILED = 'FORWARD_FAILED',
  REDIRECT_VORGANG = 'REDIRECT_VORGANG',
  FORWARD_SUCCESSFULL = 'FORWARD_SUCCESSFULL',
  PROCESS_VORGANG = 'PROCESS_VORGANG',
  SEND_POSTFACH_NACHRICHT = 'SEND_POSTFACH_NACHRICHT',
  RECEIVE_POSTFACH_NACHRICHT = 'RECEIVE_POSTFACH_NACHRICHT',
  RESEND_POSTFACH_NACHRICHT = 'RESEND_POSTFACH_MAIL',
  WIEDERVORLAGE_ERLEDIGEN = 'WIEDERVORLAGE_ERLEDIGEN',
  WIEDERVORLAGE_WIEDEREROEFFNEN = 'WIEDERVORLAGE_WIEDEREROEFFNEN',
  VORGANG_ANNEHMEN = 'VORGANG_ANNEHMEN',
  VORGANG_VERWERFEN = 'VORGANG_VERWERFEN',
  VORGANG_ZURUECKHOLEN = 'VORGANG_ZURUECKHOLEN',
  VORGANG_BEARBEITEN = 'VORGANG_BEARBEITEN',
  VORGANG_BESCHEIDEN = 'VORGANG_BESCHEIDEN',
  VORGANG_ZURUECKSTELLEN = 'VORGANG_ZURUECKSTELLEN',
  VORGANG_ABSCHLIESSEN = 'VORGANG_ABSCHLIESSEN',
  VORGANG_WIEDEREROEFFNEN = 'VORGANG_WIEDEREROEFFNEN',
  VORGANG_ZUM_LOESCHEN_MARKIEREN = 'VORGANG_ZUM_LOESCHEN_MARKIEREN',
  VORGANG_LOESCHEN = 'VORGANG_LOESCHEN',
  LOESCH_ANFORDERUNG_ZURUECKNEHMEN = 'LOESCH_ANFORDERUNG_ZURUECKNEHMEN',
  CREATE_BESCHEID = 'CREATE_BESCHEID',
  SET_AKTENZEICHEN = 'SET_AKTENZEICHEN',
  DELETE_BESCHEID = 'DELETE_BESCHEID',
  UPDATE_BESCHEID = 'UPDATE_BESCHEID',
  CREATE_BESCHEID_DOCUMENT_FROM_FILE = 'CREATE_BESCHEID_DOCUMENT_FROM_FILE',
  CREATE_BESCHEID_DOCUMENT = 'CREATE_BESCHEID_DOCUMENT',
  SEND_BESCHEID = 'SEND_BESCHEID',
  CREATE_COLLABORATION_REQUEST = 'CREATE_COLLABORATION_REQUEST',
}

export interface CreateCommandProps {
  resource: Resource;
  linkRel: string;
  command: CreateCommand;
  //set to EMPTY_STRING/'' if no snackbar should be display
  snackBarMessage?: string;
  snackBarErrorMessage?: string;
}
