import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ods-fieldset',
  standalone: true,
  imports: [CommonModule],
  template: `<fieldset class="flex flex-col gap-2" [name]="name">
    <legend *ngIf="legend" class="mb-2 font-medium text-text" data-test-id="fieldset-legend">
      {{ legend }}
    </legend>
    <ng-content />
  </fieldset>`,
})
export class FieldsetComponent {
  @Input() name: string;
  @Input() legend: string;
}
