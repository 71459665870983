/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Injectable } from '@angular/core';
import { ApiRootResource } from '@alfa-client/api-root-shared';
import { BinaryFileListResource } from '@alfa-client/binary-file-shared';
import { CommandOrder, CommandResource, CreateCommand } from '@alfa-client/command-shared';
import { StateResource } from '@alfa-client/tech-shared';
import { Store } from '@ngrx/store';
import { ResourceUri } from '@ngxp/rest';
import { Observable } from 'rxjs';
import { VorgangWithEingangLinkRel } from '../vorgang.linkrel';
import {
  AdditionalActions,
  CreateAssignUserCommand,
  VorgangFilter,
  VorgangListResource,
  VorgangResource,
  VorgangStatistic,
  VorgangView,
  VorgangWithEingangResource,
} from '../vorgang.model';
import {
  backButtonUrl,
  getStatusCommand,
  isVorgangFilterSelected,
  isVorgangViewSelected,
} from './vorgang.selectors';

import * as CommandActions from '../../../../command-shared/src/lib/+state/command.actions';
import * as VorgangActions from './vorgang.actions';
import * as VorgangSelectors from './vorgang.selectors';

@Injectable()
export class VorgangFacade {
  constructor(private readonly store: Store) {}

  //VorgangList
  public getVorgangList(): Observable<StateResource<VorgangListResource>> {
    return this.store.select(VorgangSelectors.vorgangList);
  }

  public loadVorgangList(apiRoot: ApiRootResource, linkRel: string): void {
    this.store.dispatch(VorgangActions.loadVorgangList({ apiRoot, linkRel }));
  }

  public getVorgangStatistic(): Observable<StateResource<VorgangStatistic>> {
    return this.store.select(VorgangSelectors.vorgangStatistic);
  }

  public getVorgaenge(): Observable<VorgangResource[]> {
    return this.store.select(VorgangSelectors.vorgaenge);
  }

  public loadNextPage(): void {
    this.store.dispatch(VorgangActions.loadNextPage());
  }

  public getVorgangFilter(): Observable<VorgangFilter> {
    return this.store.select(VorgangSelectors.vorgangFilter);
  }

  public isVorgangFilterSelected(vorgangFilter: VorgangFilter): Observable<boolean> {
    return this.store.select(isVorgangFilterSelected(vorgangFilter));
  }

  public getVorgangView(): Observable<VorgangView> {
    return this.store.select(VorgangSelectors.vorgangView);
  }

  public isVorgangViewSelected(vorgangView: VorgangView): Observable<boolean> {
    return this.store.select(isVorgangViewSelected(vorgangView));
  }

  public getVorgangViewRoutePath(vorgangView: VorgangView): Observable<string> {
    return this.store.select(VorgangSelectors.getVorgangViewRoutePath(vorgangView));
  }

  public getBackButtonUrl(): Observable<string> {
    return this.store.select(backButtonUrl);
  }

  //Search
  public searchVorgaengeBy(apiRoot: ApiRootResource, searchString: string, linkRel: string): void {
    this.store.dispatch(VorgangActions.searchVorgaengeBy({ apiRoot, searchString, linkRel }));
  }

  public searchForPreview(searchString: string): void {
    this.store.dispatch(VorgangActions.searchForPreview({ string: searchString }));
  }

  public setSearchString(searchString: string): void {
    this.store.dispatch(VorgangActions.setSearchString({ string: searchString }));
  }

  public getSearchString(): Observable<string> {
    return this.store.select(VorgangSelectors.searchString);
  }

  public getSearchPreviewList(): Observable<StateResource<VorgangListResource>> {
    return this.store.select(VorgangSelectors.searchPreviewList);
  }

  public clearSearchPreviewList(): void {
    this.store.dispatch(VorgangActions.clearSearchPreviewList());
  }

  public clearSearchString(): void {
    this.store.dispatch(VorgangActions.clearSearchString());
  }

  //VorgangWithEingang
  public getVorgangWithEingang(): Observable<StateResource<VorgangWithEingangResource>> {
    return this.store.select(VorgangSelectors.vorgangWithEingang);
  }

  public loadVorgangWithEingang(vorgangWithEingangUri: ResourceUri): void {
    this.store.dispatch(
      VorgangActions.loadVorgangWithEingang({ resourceUri: vorgangWithEingangUri }),
    );
  }

  public loadVorgangWithEingangWithAdditionalActions(
    vorgangWithEingangUri: ResourceUri,
    additionalActions: AdditionalActions,
  ) {
    this.store.dispatch(
      VorgangActions.loadVorgangWithEingang({
        resourceUri: vorgangWithEingangUri,
        additionalActions,
      }),
    );
  }

  public getAttachmentList(): Observable<StateResource<BinaryFileListResource>> {
    return this.store.select(VorgangSelectors.attachmentList);
  }

  public loadAttachmentList(vorgangWithEingang: VorgangWithEingangResource): void {
    this.store.dispatch(VorgangActions.loadAttachmentList({ vorgangWithEingang }));
  }

  public getRepresentationList(): Observable<StateResource<BinaryFileListResource>> {
    return this.store.select(VorgangSelectors.representationList);
  }

  public loadRepresentationList(vorgangWithEingang: VorgangWithEingangResource): void {
    this.store.dispatch(VorgangActions.loadRepresentationList({ vorgangWithEingang }));
  }

  public clearVorgangWithEingang(): void {
    this.store.dispatch(VorgangActions.clearVorgangWithEingang());
  }

  public getForwardPendingCommand(): Observable<StateResource<CommandResource>> {
    return this.store.select(VorgangSelectors.forwardPendingCommand);
  }
  public setForwardSingleCommandLoading(): void {
    this.store.dispatch(VorgangActions.setForwardingSingleCommandLoading());
  }
  public setForwardSingleCommand(commandStateResource: StateResource<CommandResource>): void {
    this.store.dispatch(VorgangActions.setForwardingSingleCommand({ commandStateResource }));
  }

  public getSendPostfachNachrichtPendingCommand(): Observable<StateResource<CommandResource>> {
    return this.store.select(VorgangSelectors.sendPostfachNachrichtPendingCommand);
  }
  public setPendingSendPostfachMailSingleCommandLoading(): void {
    this.store.dispatch(VorgangActions.setSendPostfachNachrichtSingleCommandLoading());
  }
  public setPendingSendPostfachMailSingleCommand(
    commandStateResource: StateResource<CommandResource>,
  ): void {
    this.store.dispatch(
      VorgangActions.setSendPostfachNachrichtSingleCommand({ commandStateResource }),
    );
  }

  public getAssignUserCommand(): Observable<StateResource<CommandResource>> {
    return this.store.select(VorgangSelectors.assignUserCommand);
  }

  public assignUser(
    vorgangWithEingang: VorgangWithEingangResource,
    command: CreateAssignUserCommand,
  ): void {
    this.store.dispatch(
      CommandActions.createCommand({
        resource: vorgangWithEingang,
        linkRel: VorgangWithEingangLinkRel.ASSIGN,
        command,
      }),
    );
  }

  public initAssignUser(): void {
    this.store.dispatch(VorgangActions.initAssignUser());
  }

  public getStatusCommand(order: CommandOrder): Observable<StateResource<CommandResource>> {
    return this.store.select(getStatusCommand(order));
  }

  public annehmen(vorgangWithEingang: VorgangWithEingangResource, command: CreateCommand): void {
    this.store.dispatch(
      CommandActions.createCommand({
        resource: vorgangWithEingang,
        linkRel: VorgangWithEingangLinkRel.ANNEHMEN,
        command,
      }),
    );
  }

  public bearbeiten(vorgangWithEingang: VorgangWithEingangResource, command: CreateCommand): void {
    this.store.dispatch(
      CommandActions.createCommand({
        resource: vorgangWithEingang,
        linkRel: VorgangWithEingangLinkRel.BEARBEITEN,
        command,
      }),
    );
  }

  public bescheiden(vorgangWithEingang: VorgangWithEingangResource, command: CreateCommand): void {
    this.store.dispatch(
      CommandActions.createCommand({
        resource: vorgangWithEingang,
        linkRel: VorgangWithEingangLinkRel.BESCHEIDEN,
        command,
      }),
    );
  }

  public verwerfen(vorgangWithEingang: VorgangWithEingangResource, command: CreateCommand): void {
    this.store.dispatch(
      CommandActions.createCommand({
        resource: vorgangWithEingang,
        linkRel: VorgangWithEingangLinkRel.VERWERFEN,
        command,
      }),
    );
  }

  public wiedereroeffnen(
    vorgangWithEingang: VorgangWithEingangResource,
    command: CreateCommand,
  ): void {
    this.store.dispatch(
      CommandActions.createCommand({
        resource: vorgangWithEingang,
        linkRel: VorgangWithEingangLinkRel.WIEDEREROEFFNEN,
        command,
      }),
    );
  }

  public zurueckholen(
    vorgangWithEingang: VorgangWithEingangResource,
    command: CreateCommand,
  ): void {
    this.store.dispatch(
      CommandActions.createCommand({
        resource: vorgangWithEingang,
        linkRel: VorgangWithEingangLinkRel.ZURUECKHOLEN,
        command,
      }),
    );
  }

  public zurueckstellen(
    vorgangWithEingang: VorgangWithEingangResource,
    command: CreateCommand,
  ): void {
    this.store.dispatch(
      CommandActions.createCommand({
        resource: vorgangWithEingang,
        linkRel: VorgangWithEingangLinkRel.ZURUECKSTELLEN,
        command,
      }),
    );
  }

  public abschliessen(
    vorgangWithEingang: VorgangWithEingangResource,
    command: CreateCommand,
  ): void {
    this.store.dispatch(
      CommandActions.createCommand({
        resource: vorgangWithEingang,
        linkRel: VorgangWithEingangLinkRel.ABSCHLIESSEN,
        command,
      }),
    );
  }

  public getRevokeCommand(): Observable<StateResource<CommandResource>> {
    return this.store.select(VorgangSelectors.revokeCommand);
  }

  public getVorgangExport(): Observable<StateResource<boolean>> {
    return this.store.select(VorgangSelectors.vorgangExport);
  }

  public export(vorgangWithEingang: VorgangWithEingangResource): void {
    this.store.dispatch(VorgangActions.exportVorgang({ vorgangWithEingang }));
  }
}
